import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

//Crear componente tipo popup de material UI el cual sera utilizado para confirmar la eliminacion de un registro, debe recibir como props el estado de apertura del popup y la funcion que se ejecutara al confirmar la eliminacion y el mensaje de confirmacion
export const ConfirmDelete = ({ open, onClose, onConfirm, message }: { open: boolean; onClose: () => void; onConfirm: () => void; message: string; }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    Cancelar
                </Button>
                <Button onClick={onConfirm} color='primary'>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
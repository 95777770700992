// export {}; 

export interface EstadoLiquidacion {
  id: number;
  texto: string;
  color: string;
}
  
export const estadoLiquidacion: EstadoLiquidacion[] = [
  { id: 1, texto: 'Abierto', color: '#FFFFFF' }, // Blanco
  { id: 2, texto: 'Finalizado', color: '#FFC300' }, // Verde
  { id: 3, texto: 'Liquidado', color: '#6D92AF' }, // Amarillo
  { id: 4, texto: 'Cancelado', color: '#808080' }, // Naranja
];
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getDetalleServicio, updateServicio, updateDetalleServicio } from '../../api/servicios';
import { getProductosEnInventario } from '../../api/inventarios';
import { getInstaladores } from '../../api/instaladores';
import { UseAlert } from '../../components/newAlert/NewAlert';
import { SelectChangeEvent } from '@mui/material/Select';
import { InstaladoresCellV } from './_InstaladoresCellV';
import { deleteLiquidacion } from '../../api/liquidaciones';
import { useAuth } from '../../context/auth';

interface ViewServicioProps {
  servicio: any;
  onClose: () => void;
}

const ViewServicio: React.FC<ViewServicioProps> = ({ servicio, onClose }) => {
  const [detalleServicio, setDetalleServicio] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [productos, setProductos] = useState<any[]>([]);
  const [instaladores, setInstaladores] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const { NewNotification } = UseAlert();
  const [instaladoresByService, setInstaladoresByService] = useState<any[]>([]);
  const [instaladoresByServiceToDelete, setInstaladoresByServiceToDelete] = useState<any[]>([]);
  const [serviciosToDelete, setServiciosToDelete] = useState<any[]>([]);
  const [instaladoresLoaded, setInstaladoresLoaded] = useState(false);
  const [editableServicio, setEditableServicio] = useState({ ...servicio });
  const { permisos } = useAuth();
  const { Prm_Serv_Modificar } = permisos;

  const fetchDetalleServicio = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDetalleServicio(servicio.Srv_Id);
      if (response.isOk) {
        setDetalleServicio(response.data);
        const current: any[] = [];
        response.data.forEach((element: any) => {
          current.push(...element.instaladores);
        });
        setInstaladoresByService(current);
      }
    } finally {
      setLoading(false);
    }
  }, [servicio.Srv_Id, setInstaladoresByService]);

  const fetchProductos = useCallback(async () => {
    setLoading(true);
    const response = await getProductosEnInventario();
    if (response.Success) {
      setProductos(response.Data);
    }
    setLoading(false);
  }, []);

  const fetchInstaladores = useCallback(async () => {
    setLoading(true);
    const response = await getInstaladores();
    if (response.isOk) {
      setInstaladores(response.data);
      setInstaladoresLoaded(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (servicio.Srv_Id) {
      fetchDetalleServicio();
      fetchProductos();
      fetchInstaladores();
    }
  }, [fetchDetalleServicio, servicio.Srv_Id]);

  const handleAddDetalleService = useCallback(
    (event: SelectChangeEvent<string>) => {
      const productId = event.target.value;
      const currentProduct = productos.find((p) => p.Pr_Id === productId);

      if (!currentProduct) return;

      if (detalleServicio.some((x) => x.SrvD_Pr_Id === currentProduct.Pr_Id)) {
        NewNotification('El producto ya se encuentra en el detalle', 'warning');
        return;
      }

      const valorInventario = parseInt(currentProduct.Inv_ValorVenta);
      const iva = valorInventario * 0.19;
      const newId = `temp-${Date.now()}`;

      setDetalleServicio((prev) => [
        ...prev,
        {
          SrvD_Id: newId,
          SrvD_Pr_Id: currentProduct.Pr_Id,
          Pr_Nombre: `${currentProduct.Pr_Nombre} ${currentProduct.Un_Nombre}`,
          SrvD_Cantidad: 1,
          SrvD_ValorUnitario: valorInventario,
          SrvD_Iva: iva,
          SrvD_Total: iva + valorInventario,
          SrvD_Detalle: '',
          instaladores: [],
        },
      ]);
      setSelectedProduct(''); // Resetear la selección
    },
    [productos, detalleServicio, NewNotification]
  );

  const memoizedDetalleServicio = useMemo(() => detalleServicio, [detalleServicio]);

  const handleDeleteRow = useCallback((row: any) => {
    const dataToDelte = row.row;

    if (!dataToDelte.SrvD_Id.toString().startsWith('temp')) {
      setServiciosToDelete((prev) => [...prev, dataToDelte]);
    }
    setDetalleServicio((prevDetalle) => prevDetalle.filter((x) => x.SrvD_Id !== dataToDelte.SrvD_Id));
  }, []);

  const createColumns = (isEditing: boolean, handleDeleteRow: (row: any) => void): GridColDef[] => [
    {
      field: 'Pr_Nombre',
      headerName: 'Producto',
      width: 200,
      renderCell: (params: any) => {
        return <span>{`${params.row?.Pr_Nombre || ''} ${params.row?.Un_Nombre || ''}`}</span>;
      },
    },
    {
      field: 'SrvD_Cantidad',
      headerName: 'Cantidad',
      flex: 1,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      editable: isEditing,
    },
    {
      field: 'SrvD_ValorUnitario',
      headerName: 'Valor Unitario',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      editable: isEditing,
    },
    {
      field: 'SrvD_Iva',
      headerName: 'IVA',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: isEditing,
    },
    {
      field: 'SrvD_Total',
      headerName: 'Total',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: isEditing,
    },
    {
      field: 'SrvD_Detalle',
      headerName: 'Detalle',
      flex: 1,
      editable: isEditing,
    },
    {
      field: 'instaladores',
      headerName: 'Instaladores',
      flex: 1,
      renderCell: (params) => <InstaladoresCellV instaladores={instaladores} lstInstaladores={instaladoresByService} setInstaladores={setInstaladoresByService} setInstaladoresToDelete={setInstaladoresByServiceToDelete} idActual={params.row.SrvD_Id} isEditing={isEditing} />,
    },
    {
      field: 'DeleteButton',
      headerName: 'Acción',
      width: 120,
      hideable: !isEditing,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteRow(params)} disabled={!isEditing}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  const columns = useMemo(() => {
    if (instaladoresLoaded) {
      return createColumns(isEditing, handleDeleteRow);
    }
    return [];
  }, [isEditing, handleDeleteRow, instaladoresLoaded, instaladoresByService, setInstaladoresByServiceToDelete]);

  const handleRowUpdate = useCallback((updatedRow: any, oldRow: any) => {
    const { SrvD_Cantidad, SrvD_ValorUnitario } = updatedRow;

    if (SrvD_Cantidad !== oldRow.SrvD_Cantidad || SrvD_ValorUnitario !== oldRow.SrvD_ValorUnitario) {
      const newIva = SrvD_ValorUnitario * SrvD_Cantidad * 0.19;
      const newTotal = SrvD_Cantidad * SrvD_ValorUnitario + newIva;
      updatedRow = {
        ...updatedRow,
        SrvD_Iva: newIva,
        SrvD_Total: newTotal,
      };
    }

    setDetalleServicio((prevDetalle) => prevDetalle.map((row) => (row.SrvD_Id === updatedRow.SrvD_Id ? updatedRow : row)));

    return updatedRow;
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>) => {
    const { name, value } = e.target;
    setEditableServicio((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSave = useCallback(async () => {
    setLoadingSave(true);
    const servicioData = {
      Srv_Cl_Id: editableServicio.Srv_Cl_Id,
      Srv_FechaServicio: editableServicio.Srv_FechaServicio,
      Srv_VehiculoMarca: editableServicio.Srv_VehiculoMarca,
      Srv_VehiculoReferencia: editableServicio.Srv_VehiculoReferencia,
      Srv_VehiculoPlacaOSerial: editableServicio.Srv_VehiculoPlacaOSerial,
      Srv_Direccion: editableServicio.Srv_Direccion,
      Srv_EsDomicilio: editableServicio.Srv_EsDomicilio,
      Srv_Observaciones: editableServicio.Srv_Observaciones,
      Srv_Factura: editableServicio.Srv_Factura,
      Srv_Total: calculateTotal(),
    };

    const updateServiceResponse = await updateServicio(servicio.Srv_Id, servicioData);
    if (!updateServiceResponse.isOk) {
      NewNotification(updateServiceResponse.message || 'Error actualizando el servicio', 'error');
      return;
    }

    const detalle = {
      detalle: detalleServicio.map((row) => {
        const { instaladores, ...detalle } = row;
        return {
          SrvD_Id: parseInt(detalle.SrvD_Id),
          SrvD_Pr_Id: parseInt(detalle.SrvD_Pr_Id),
          SrvD_Cantidad: parseInt(detalle.SrvD_Cantidad),
          SrvD_ValorUnitario: parseInt(detalle.SrvD_ValorUnitario),
          SrvD_Iva: detalle.SrvD_Iva,
          SrvD_Total: detalle.SrvD_Total,
          SrvD_Detalle: detalle.SrvD_Detalle,
          instaladores: instaladoresByService
            .filter((x) => x.SrvDI_SrvD_Id === detalle.SrvD_Id)
            .map((inst: any) => ({
              SrvDI_In_Id: inst.SrvDI_In_Id,
              SrvDI_Valor: parseInt(inst.SrvDI_Valor),
              SrvDI_Detalle: inst.SrvDI_Detalle,
              SrvDI_Estado: inst.SrvDI_Estado,
            })),
        };
      }),
    };

    if (detalle.detalle.length >= 0) {
      const updateDetalleResponse = await updateDetalleServicio(servicio.Srv_Id, detalle);
      if (!updateDetalleResponse.isOk) {
        NewNotification(updateDetalleResponse.message || 'Error actualizando el detalle del servicio', 'error');
        return;
      }
    }

    // Delete instaladores
    if (serviciosToDelete.length > 0) {
      const detalleDataDel = {
        detalle: serviciosToDelete.map((detalle) => ({
          SrvD_Id: detalle.SrvD_Id,
          SrvD_Pr_Id: detalle.SrvD_Pr_Id,
          SrvD_Cantidad: detalle.SrvD_Cantidad,
          SrvD_ValorUnitario: detalle.SrvD_ValorUnitario,
          SrvD_Iva: detalle.SrvD_Iva,
          SrvD_Total: detalle.SrvD_Total,
          SrvD_Detalle: detalle.SrvD_Detalle,
          instaladores: [],
          delete: true,
        })),
      };

      const updateDetalleResponse = await updateDetalleServicio(servicio.Srv_Id, detalleDataDel);
      if (!updateDetalleResponse.isOk) {
        NewNotification(updateDetalleResponse.message || 'Error actualizando el detalle del servicio', 'error');
        return;
      }
    }

    for (const detalle of instaladoresByServiceToDelete) {
      const resDel = await deleteLiquidacion(detalle.SrvDI_Id);
      if (!resDel.isOk) {
        NewNotification(resDel.message || `Error eliminando el instalador ${detalle.In_Nombre}`, 'error');
      }
    }

    NewNotification('Servicio actualizado correctamente', 'success');
    setIsEditing(false);
    setLoadingSave(false);
    onClose();
  }, [editableServicio, detalleServicio, instaladoresByService, NewNotification, instaladoresByServiceToDelete]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    setEditableServicio({ ...servicio });
  }, [servicio]);

  const calculateTotal = useCallback(() => {
    return detalleServicio.reduce((acc, current) => acc + parseInt(current.SrvD_Total), 0);
  }, [detalleServicio]);

  const handleEdit = () => {
    if (servicio.Srv_Estado >= 3 && servicio.Srv_Estado < 7) {
      NewNotification('No se puede editar un servicio que ya ha sido finalizado', 'warning');
      return;
    }
    setIsEditing(!isEditing);
  };

  if (loading || !instaladoresLoaded) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <TextField label='Nombre del Cliente' value={editableServicio.Cl_Nombre} fullWidth size='small' disabled />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField label='Fecha Servicio' type='date' name='Srv_FechaServicio' value={formatDate(editableServicio.Srv_FechaServicio)} fullWidth size='small' InputLabelProps={{ shrink: true }} disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth size='small'>
              <InputLabel>Es Domicilio</InputLabel>
              <Select name='Srv_EsDomicilio' value={editableServicio.Srv_EsDomicilio} label='Es Domicilio' disabled={!isEditing} onChange={handleInputChange}>
                <MenuItem value={'0'}>No</MenuItem>
                <MenuItem value={'1'}>Sí</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField label='Factura' name='Srv_Factura' value={editableServicio.Srv_Factura} fullWidth size='small' disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField label='Marca del Vehículo' name='Srv_VehiculoMarca' value={editableServicio.Srv_VehiculoMarca} fullWidth size='small' InputLabelProps={{ shrink: true }} disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField label='Referencia del Vehículo' name='Srv_VehiculoReferencia' value={editableServicio.Srv_VehiculoReferencia} fullWidth size='small' InputLabelProps={{ shrink: true }} disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField label='Placa o Serial del Vehículo' name='Srv_VehiculoPlacaOSerial' value={editableServicio.Srv_VehiculoPlacaOSerial} fullWidth size='small' InputLabelProps={{ shrink: true }} disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label='Dirección' name='Srv_Direccion' value={editableServicio.Srv_Direccion} fullWidth size='small' disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label='Observaciones' name='Srv_Observaciones' value={editableServicio.Srv_Observaciones} fullWidth size='small' disabled={!isEditing} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label='Restante crédito ' name='Srv_ValorRestante' value={`$${parseInt(editableServicio.Srv_ValorRestante)}`} fullWidth size='small' disabled={true} onChange={handleInputChange} />
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <hr style={{ color: '#aa44cc', width: '80%' }} />
        <Grid item xs={9} sm={9}>
          <FormControl fullWidth size='small'>
            <InputLabel>Producto</InputLabel>
            <Select label='Producto' value={selectedProduct} onChange={handleAddDetalleService} disabled={!isEditing}>
              {productos.map((producto) => (
                <MenuItem key={`cmb-${producto.Pr_Id}`} value={producto.Pr_Id}>
                  {producto.Pr_Nombre} {producto.Un_Nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3}>
          <TextField label='Total' value={`$${calculateTotal()}`} fullWidth size='small' disabled />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid rows={memoizedDetalleServicio} columns={columns} getRowId={(row) => row.SrvD_Id} processRowUpdate={handleRowUpdate} />
          </Box>
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='space-between' sx={{ marginTop: 2 }}>
        <Button onClick={handleSave} variant='outlined' size='small' disabled={!isEditing}>
          {loadingSave ? <CircularProgress size={20} /> : 'Guardar'}
        </Button>
        {Prm_Serv_Modificar === 1 && (
          <Button onClick={handleEdit} variant='outlined' size='small'>
            {isEditing ? 'Cancelar' : 'Editar'}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default React.memo(ViewServicio);

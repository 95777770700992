import { UseApi } from '../utils/axios-pettitions';

export async function signIn(username: string, password: string) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('auth', 'POST', null, { username, password });    
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    localStorage.setItem('token', '7&333%*-.@2aB773.&^456..rRtb');
    localStorage.setItem('user', JSON.stringify(response.Data));

    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error de autenticación',
    };
  }
}

export async function getUser() {
  try {
    //Obtener el user del localstorage y convertirlo a objeto
    const user = localStorage.getItem('user');
    if (user) {
      return {
        isOk: true,
        data: JSON.parse(user) ,
      };
    }

    return {
      isOk: false,
      data: { Us_Nombre: 'Usuario' } ,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

export const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      textAlign="center"
      bgcolor="background.paper"
      p={3}
      borderRadius={2}
      boxShadow={3}
    >
      <LockIcon sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
      <Typography variant="h4" sx={{ mb: 2 }}>
        ACCESO DENEGADO
      </Typography>
      <Typography sx={{ color: 'text.secondary', mb: 4 }}>
        Lo sentimos, actualmente no tienes permisos para acceder a esta sección.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/home')}
        startIcon={<LockIcon />}
      >
        Regresar a la página principal
      </Button>
    </Box>
  );
};


import React, { useState, useEffect } from 'react';
import { getMonitoreoInventario } from '../../api/monitoreoInventario';
import { Button, TextField, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';

const ModalMonitoreoInventario = React.memo(({ onClose }: any) => {
  const [monitoreo, setMonitoreo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ startDate: '', endDate: '' });

  useEffect(() => {
    fetchMonitoreo();
  }, []);

  const fetchMonitoreo = async (params?: any) => {
    setLoading(true);
    const response = await getMonitoreoInventario(params);
    if (response.Success) {
      setMonitoreo(response.Data);
    }
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    await fetchMonitoreo(filters);
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
        <TextField label="Fecha Inicio" size='small' name="startDate" fullWidth type="date" value={filters.startDate} onChange={handleChange} InputLabelProps={{ shrink: true }} />
        <TextField label="Fecha Fin" size='small' name="endDate"  type="date" fullWidth sx={{marginLeft:1}} value={filters.endDate} onChange={handleChange} InputLabelProps={{ shrink: true }} />
        <Button onClick={handleSubmit} variant="outlined" fullWidth sx={{marginLeft:1}}>
          Filtrar
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Producto</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Acción</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Detalle</TableCell>
                <TableCell>Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monitoreo.map((item: any) => (
                <TableRow key={item.Mo_Id}>
                  <TableCell>{item.Mo_Id}</TableCell>
                  <TableCell>{item.Pr_Nombre} {item.Un_Nombre} </TableCell>
                  <TableCell>{item.Us_Nombre}</TableCell>
                  <TableCell>{item.Mo_Accion}</TableCell>
                  <TableCell>{item.Mo_Cantidad}</TableCell>
                  <TableCell>{item.Mo_Detalle}</TableCell>
                  <TableCell>{item.Mo_Fecha}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button onClick={onClose} variant="outlined" color="error">
          Cerrar
        </Button>
      </Box>
    </div>
  );
});

export default ModalMonitoreoInventario;

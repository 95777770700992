import React from 'react';
import { Typography, TextareaAutosize, TextField, Button, Box } from '@mui/material';

// Función para obtener el texto del estado
const getEstadoText = (estado: number) => {
  switch (estado) {
    case 1:
      return 'Activo';
    case 2:
      return 'Finalizado';
    case 3:
      return 'Liquidado';
    case 4:
      return 'Cancelado';
    default:
      return 'Desconocido';
  }
};

const ViewLiquidacion = React.memo(({ liquidacion, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label="Servicio" value={liquidacion.SrvD_Srv_Id} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Empleado" value={`${liquidacion.In_Nombre} ${liquidacion.In_Apellido}`} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Fecha de creacion" value={liquidacion.SrvDl_FechaCreacion} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Fecha de finalizacion" value={liquidacion.SrvDl_FechaFinalizacion} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Estado" value={getEstadoText(liquidacion.SrvDI_Estado)} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Valor" value={liquidacion.SrvDI_Valor} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Fecha de liquidacion" value={liquidacion.SrvDI_FechaLiquidacion} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <Box sx={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }}>
        <Typography variant="body1" sx={{ marginBottom: '0.5rem' }} style={{color: 'gray'}}>Detalle</Typography>
        <TextareaAutosize
          minRows={5}
          value={"asdfasdfasdf"}
          disabled
          style={{ width: '100%' }}
        />
      </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewLiquidacion;

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { getCreditoHistory } from '../../api/creditos';

export const History = React.memo(({ onClose, open, data }: any) => {
  const [loading, setLoading] = React.useState(false);
  const [dataCredito, setDataCredito] = React.useState<any[]>([]);
  useEffect(() => {
    if (open) {
      fetchCredito();
    }
  }, [open, data]);

  const fetchCredito = useCallback(() => {
    setLoading(true);
    getCreditoHistory(data.Cl_Id)
      .then((response) => {
        if (response.isOk) {
          setDataCredito(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setDataCredito, data]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>Historial de movimientos para el cliente {data && data.Cl_Nombre}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Servicio</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Nota</TableCell>
                  <TableCell>Monto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataCredito.map((item: any) => (
                  <TableRow key={item.Crd_Id}>
                    <TableCell>{item.Crd_Srv_Id}</TableCell>
                    <TableCell>{item.Us_Nombre}</TableCell>
                    <TableCell>{item.Crd_Fecha}</TableCell>
                    <TableCell>{item.Crd_Nota}</TableCell>
                    <TableCell>$ {parseInt(item.Crd_Monto)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button onClick={onClose} variant='outlined' sx={{mt:3}} color='error' size='small'>
          Cerrar
        </Button>
      </DialogContent>
    </Dialog>
  );
});

import React, { useState, useEffect } from 'react';
import { updateUsuario } from '../../../api/usuarios';
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material';

const EditUsuario = React.memo(({ usuario, onClose, LstPerfiles }: any) => {
  const [formData, setFormData] = useState(usuario);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Us_Nombre: '',
    Us_Usuario: '',
    Us_Perfil_Id : '',
    Us_Contrasena: '',
  });

  useEffect(() => {
    setFormData(usuario);
  }, [usuario]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Us_Nombre: '', Us_Usuario: '', Us_Contrasena: '', Us_Perfil_Id : '' };
    if (!formData.Us_Nombre) tempErrors.Us_Nombre = 'El nombre es requerido';
    if (!formData.Us_Usuario) tempErrors.Us_Usuario = 'El usuario es requerido';
    if (!formData.Us_Contrasena) tempErrors.Us_Contrasena = 'La contraseña es requerida';
    if (!formData.Us_Perfil_Id) tempErrors.Us_Perfil_Id = 'El perfil es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await updateUsuario(formData.Us_Id, formData);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Us_Nombre' value={formData.Us_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Us_Nombre} helperText={errors.Us_Nombre} />
        <TextField label='Usuario' name='Us_Usuario' value={formData.Us_Usuario} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Usuario} helperText={errors.Us_Usuario} />
        <TextField select label='Perfil' name='Us_Perfil_Id' value={formData.Us_Perfil_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Perfil_Id} helperText={errors.Us_Perfil_Id}>
          {LstPerfiles.map((perfil: any) => (
            <MenuItem key={perfil.Pe_Id} value={perfil.Pe_Id}>
              {perfil.Pe_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField label='Contraseña' name='Us_Contrasena' type='password' value={formData.Us_Contrasena} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Contrasena} helperText={errors.Us_Contrasena} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditUsuario;

// src/pages/Login.tsx
import React, { useState } from 'react';
import { useAuth } from '../../context/auth';
import './login.scss';
import { Button } from '@mui/material';
import { Login as LoginBtn } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { UseAlert } from '../../components/newAlert/NewAlert';
import { LoadingButton } from '@mui/lab';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { NewNotification } = UseAlert();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username) {
      setUsernameError(true);
      return;
    }

    if (!password) {
      setPasswordError(true);
      return;
    }
    try {
      setLoading(true);
      const result = await signIn(username, password);

      if (!result.isOk && result.message) {
        NewNotification(result.message, 'warning');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setUsernameError(false);
  };

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  return (
    <div className={'single-card'}>
      <div className={'dx-card content'}>
        <div className={'header'}>
          <img src='images/logo.png' className='img-logo' alt='logo' />

          <hr />
          <div className={'title'}>Autenticación</div>
          <div className={'description'}>Bienvenido al sistema de servicios de Safety Sound, para ingresar, por favor digite el usuario y la contraseña asignados.</div>
        </div>
        <form onSubmit={handleLogin}>
          <Box>
            <TextField label='Usuario' size='small' type='text' sx={{ width: '100%', marginBottom: 1 }} onChange={handleUserChange} error={usernameError} helperText={usernameError ? 'Usuario requerido' : ''} />
            <TextField label='Contraseña' sx={{ width: '100%', marginBottom: 3 }} type='password' size='small' onChange={handlePassChange} error={passwordError} helperText={passwordError ? 'Contraseña requerida' : ''} />
            <Button disabled={loading} variant='outlined' type='submit' startIcon={loading ? <LoadingButton loading={true} /> : <LoginBtn />} sx={{ width: '100%' }}>
              Ingresar
            </Button>
          </Box>
        </form>
        {/*}
          <Button variant='text' sx={{ width: '100%', marginTop: 5 }}>
            Olvidé mi contraseña
          </Button>        
        */}
      </div>
    </div>
  );
};

export default Login;

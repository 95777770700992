import React, { useState, useEffect } from 'react';
import { updateUnidad } from '../../../api/unidades';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const EditUnidad = React.memo(({ unidad, onClose }: any) => {
  const [formData, setFormData] = useState(unidad);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Un_Nombre: '',
  });

  useEffect(() => {
    setFormData(unidad);
  }, [unidad]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Un_Nombre: '' };
    if (!formData.Un_Nombre) tempErrors.Un_Nombre = 'El nombre es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await updateUnidad(formData.Un_Id, formData);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Un_Nombre' value={formData.Un_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Un_Nombre} helperText={errors.Un_Nombre} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditUnidad;

import React, { useState, useEffect, useCallback } from 'react';
import { getServicios, updateEstadoServicio } from '../../api/servicios';
import { estadosServicio } from './types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogContent, TextField, CircularProgress, TablePagination, Box, MenuItem, Select, InputLabel, FormControl, Grid, Slide, AppBar, Toolbar, Typography } from '@mui/material';
import { Add, Close, Update, Visibility } from '@mui/icons-material';
import AddServicio from './_AddServicio';
import ViewServicio from './_ViewServicio';
import { TransitionProps } from '@mui/material/transitions';
import { Servicio } from './types';
import moment from 'moment';
import { UseAlert } from '../../components/newAlert/NewAlert';
import { useAuth } from '../../context/auth';
import { AccessDenied } from '../../components/AccessDenied/Index';

const Servicios = () => {
  const [servicios, setServicios] = useState<any[]>([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [estadoFilter, setEstadoFilter] = useState('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loadingEstado, setLoadingEstado] = useState<number | null>(null);
  const [selectedServicio, setSelectedServicio] = useState<Servicio | null>(null);
  const { NewNotification } = UseAlert();
  const { permisos } = useAuth();
  const { Prm_Serv_Crear, Prm_Serv_Camb_Estado, Prm_Serv_Ver, Prm_Credito_Cambiar } = permisos;

  const handleViewServicio = (servicio: Servicio) => {
    setSelectedServicio(servicio);
    setOpenView(true);
  };

  useEffect(() => {
    fetchServicios();
  }, []);

  const fetchServicios = useCallback(async () => {
    setLoading(true);
    const response = await getServicios();
    if (response.isOk) {
      setServicios(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleEstadoFilterChange = useCallback((e: any) => {
    setEstadoFilter(e.target.value as string);
  }, []);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  }, []);

  const handleEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleEstadoChange = useCallback(async (id: number, newEstado: string, factura: string, oldEstado: number) => {
    if (newEstado === '3' && factura === '') {
      NewNotification('El servicio no puede ser finalizado sin una factura', 'warning');
      return;
    }
    if(newEstado === '4' && !Prm_Credito_Cambiar){
      NewNotification('No cuenta con permisos para poner un servicio en crédito', 'warning');
      return;
    }

    if(oldEstado === 4){
      NewNotification('No puede cambiar el estado de un servicio en crédito', 'warning');
      return;
    }
    if(newEstado === '4'){
      const confirmation = window.confirm('¿Está seguro que desea poner el servicio en crédito?\n Una vez puesto en crédito no podrá pasarse a otro estado.')
      if(!confirmation){
        return;
      }
    }

    setLoadingEstado(id);
    const response = await updateEstadoServicio(id, parseInt(newEstado));
    setLoadingEstado(null);
    if (response.isOk) {
      setServicios((prevServicios) => prevServicios.map((servicio: any) => (servicio.Srv_Id === id ? { ...servicio, Srv_Estado: newEstado } : servicio)));
    }
  }, []);

  const filteredServicios = servicios.filter((servicio: any) => {
    const servicioFecha = moment(servicio.Srv_FechaServicio).startOf('day');
    const startDateNormalized = startDate ? moment(startDate).startOf('day') : null;
    const endDateNormalized = endDate ? moment(endDate).endOf('day') : null;

    const matchesSearchTerm = servicio.Cl_Nombre.toLowerCase().includes(searchTerm.toLowerCase()) || servicio.Srv_VehiculoPlacaOSerial.toLowerCase().includes(searchTerm.toLowerCase()) || servicio.Srv_Factura.toLowerCase().includes(searchTerm.toLowerCase()) || servicio.Srv_Id.toString() === searchTerm;

    const matchesEstadoFilter = estadoFilter === '' || servicio.Srv_Estado.toString() === estadoFilter;

    const matchesStartDate = startDateNormalized === null || servicioFecha.isSameOrAfter(startDateNormalized);

    const matchesEndDate = endDateNormalized === null || servicioFecha.isSameOrBefore(endDateNormalized);

    return matchesSearchTerm && matchesEstadoFilter && matchesStartDate && matchesEndDate;
  });

  if (Prm_Serv_Ver === 0) {
    return <AccessDenied />;
  }

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Grid container spacing={2} alignItems='center' mb={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>Estado</InputLabel>
                <Select value={estadoFilter} onChange={handleEstadoFilterChange} label='Estado'>
                  <MenuItem value=''>
                    <em>Todos</em>
                  </MenuItem>
                  {estadosServicio.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id.toString()}>
                      {estado.texto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Fecha Inicio' type='date' value={startDate || ''} onChange={handleStartDateChange} InputLabelProps={{ shrink: true }} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Fecha Fin' type='date' value={endDate || ''} onChange={handleEndDateChange} InputLabelProps={{ shrink: true }} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              {Prm_Serv_Crear === 1 && (
                <IconButton onClick={() => setOpenAdd(true)} title='Agregar Servicio' sx={{ border: 'solid', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                  <Add />
                </IconButton>
              )}
              <IconButton onClick={fetchServicios} title='Actualizar' sx={{ border: 'solid', borderColor: '#888', marginLeft: '3px', borderWidth: '1px', padding: '5px' }}>
                <Update />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Servicio</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Placa/Serial</TableCell>
                  <TableCell>Factura</TableCell>
                  <TableCell>Referencia</TableCell>
                  <TableCell>Fecha Servicio</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServicios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((servicio: any) => (
                  <TableRow key={servicio.Srv_Id}>
                    <TableCell>{servicio.Srv_Id}</TableCell>
                    <TableCell>{servicio.Cl_Nombre}</TableCell>
                    <TableCell>{servicio.Srv_VehiculoPlacaOSerial}</TableCell>
                    <TableCell>{servicio.Srv_Factura}</TableCell>
                    <TableCell>{servicio.Srv_VehiculoReferencia}</TableCell>
                    <TableCell>{moment(servicio.Srv_FechaServicio).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>
                      {loadingEstado === servicio.Srv_Id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Select disabled={Prm_Serv_Camb_Estado === 0} value={servicio.Srv_Estado.toString()} style={{ background: estadosServicio.find((estado) => estado.id === parseInt(servicio.Srv_Estado))?.color }} onChange={(e) =>handleEstadoChange(servicio.Srv_Id, e.target.value as string, servicio.Srv_Factura, servicio.Srv_Estado) } fullWidth size='small'>
                          {estadosServicio.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id.toString()}>
                              {estado.texto}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleViewServicio(servicio);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredServicios.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openAdd} onClose={() => setOpenAdd(false)} fullScreen TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setOpenAdd(false);
              }}
              aria-label='close'>
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              NUEVO SERVICIO
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <AddServicio
            onClose={() => {
              setOpenAdd(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openView}
        onClose={() => {
          setOpenView(false);
        }}
        fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setOpenView(false);
              }}
              aria-label='close'>
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              SERVICIO #{selectedServicio?.Srv_Id} - ESTADO: {estadosServicio.find((estado) => estado.id === parseInt(selectedServicio?.Srv_Estado || '1'))?.texto}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {selectedServicio && (
            <ViewServicio
              servicio={selectedServicio}
              onClose={() => {
                setOpenView(false);
                fetchServicios();
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Servicios;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

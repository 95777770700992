import React, { useState, useEffect } from 'react';
import { updateLiquidaciones } from '../../api/liquidaciones';
import { Button, TextField, Box, CircularProgress } from '@mui/material';
// export {}; 

const EditLiquidacion = React.memo(({ liquidacion, onClose }: any) => {
    const [formData, setFormData] = useState(liquidacion);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        SrvDI_Valor: '',
        SrvDI_Detalle: ''
    });

    useEffect(() => {
        setFormData(liquidacion);
    }, [liquidacion]); 

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
    };

    const validate = () => {
    let tempErrors = { SrvDI_Valor: '', SrvDI_Detalle: ''};
    if (!formData.SrvDI_Valor) tempErrors.SrvDI_Valor = 'El valor es requerido';
    if (!formData.SrvDI_Detalle) tempErrors.SrvDI_Detalle = 'El detalle es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
    };

    const handleSubmit = async () => {
        if (!validate()) return;
        setLoading(true);
        await updateLiquidaciones(formData.SrvDI_Id, formData);
        setLoading(false);
        onClose();
      };

    return (
        <div>
          <form>
            <TextField label='Valor' name='SrvDI_Valor' value={formData.SrvDI_Valor} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.SrvDI_Valor} helperText={errors.SrvDI_Valor} />
            <TextField label='Detalle' name='SrvDI_Detalle' value={formData.SrvDI_Detalle} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.SrvDI_Detalle} helperText={errors.SrvDI_Detalle} />
            <Box display='flex' justifyContent='space-between'>
              <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Actualizar'}
              </Button>
              <Button onClick={onClose} variant='outlined' color='error' size='small'>
                Cancelar
              </Button>
            </Box>
          </form>
        </div>
      );
});

export default EditLiquidacion;
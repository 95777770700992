import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Grid, IconButton, MenuItem, Select, InputLabel, FormControl, Box, CircularProgress,  Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Delete, Edit, Search } from '@mui/icons-material';
import { DataGrid, GridColDef,  GridRowParams } from '@mui/x-data-grid';
import { getProductosEnInventario } from '../../api/inventarios';
import { getInstaladores } from '../../api/instaladores';
import { createServicio } from '../../api/servicios';
import { Servicio } from './types';
import { SelectChangeEvent } from '@mui/material';
import { UseAlert } from '../../components/newAlert/NewAlert';
import CreateCliente from '../Configuracion/Clientes/_Create';
import { InstaladoresCell } from './_InstaladoresCell';
import { useAuth } from '../../context/auth';

const AddServicio = ({ onClose }: { onClose: () => void }) => {
  const { NewNotification } = UseAlert();
  const [productos, setProductos] = useState<any[]>([]);
  const [formErrors, setFormErrors] = useState({ Srv_FechaServicio: '', Srv_VehiculoMarca: '', Srv_VehiculoReferencia: '', Srv_VehiculoPlacaOSerial: '' });
  const { user } = useAuth();
  const [instaladores, setInstaladores] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [detalleServicio, setDetalleServicio] = useState<any[]>([]);
  const [instaladoresByService, setInstaladoresByService] = useState<any[]>([]);
  const [currentCliente, setCurrentCliente] = useState<any | null>(null);
  const [servicio, setServicio] = useState<Servicio>({
    Srv_Cl_Id: '',
    Srv_Us_Id: '',
    Srv_FechaServicio: '',
    Srv_VehiculoMarca: '',
    Srv_VehiculoReferencia: '',
    Srv_VehiculoPlacaOSerial: '',
    Srv_Direccion: '',
    Srv_EsDomicilio: '0',
    Srv_Observaciones: '',
    Srv_Factura: '',    
    detalle: [],
  });

  useEffect(() => {
    fetchProductos();
    fetchInstaladores();
  }, []);

  const fetchProductos = useCallback(async () => {
    setLoading(true);
    const response = await getProductosEnInventario();
    if (response.Success) {
      setProductos(response.Data);
    }
    setLoading(false);
  }, []);

  const fetchInstaladores = useCallback(async () => {
    setLoading(true);
    const response = await getInstaladores();
    if (response.isOk) {
      setInstaladores(response.data);
    }
    setLoading(false);
  }, []);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setServicio((prevServicio) => ({
      ...prevServicio,
      [name]: value,
    }));
  }, []);

  const handleSelectChange = useCallback((e: SelectChangeEvent<string> | SelectChangeEvent<number>) => {
    const { name, value } = e.target;
    setServicio((prevServicio) => ({
      ...prevServicio,
      [name!]: value,
    }));
  }, []);

  const handleFindClient = useCallback(() => {
    setOpenCreate(true);
    setCurrentCliente(null);
  }, []);

  const handleDeleteRow = useCallback((row: GridRowParams) => {
    setDetalleServicio((prevDetalle) => prevDetalle.filter((x) => x.id !== row.id));
    setInstaladoresByService((prevInstaladores) => prevInstaladores.filter((x) => x.idService !== row.id));
  }, []);

  const handleAddDetalleService = useCallback(
    (e: any) => {
      const currentProduct = e.target.value;
      if (detalleServicio.filter((x) => x.SrvD_Pr_Id === currentProduct.Pr_Id).length > 0) {
        NewNotification('El producto ya se encuentra en el detalle', 'warning');
        return;
      }

      const newDetalleServicio = [...detalleServicio];
      const valorInventario = parseInt(currentProduct.Inv_ValorVenta);
      const iva = valorInventario * 0.19;
      newDetalleServicio.push({
        id: Date.now(), // Asegúrate de que cada fila tenga un `id` único
        SrvD_Pr_Id: currentProduct.Pr_Id,
        Pr_Nombre: `${currentProduct.Pr_Nombre} ${currentProduct.Un_Nombre}`,
        SrvD_Cantidad: 1,
        SrvD_ValorUnitario: valorInventario,
        SrvD_Iva: iva,
        SrvD_Total: iva + valorInventario,
        SrvD_Detalle: '',
        instaladores: [],
      });
      setDetalleServicio(newDetalleServicio);
    },
    [detalleServicio]
  );

  const columns: GridColDef[] = [
    { field: 'Pr_Nombre', headerName: 'Producto', flex: 1, editable: false },
    { field: 'SrvD_Cantidad', headerName: 'Cantidad', flex: 1, editable: true },
    { field: 'SrvD_ValorUnitario', headerName: 'Valor Unitario', flex: 1, editable: true },
    { field: 'SrvD_Iva', headerName: 'IVA', flex: 1, editable: false },
    { field: 'SrvD_Total', headerName: 'Total', flex: 1, editable: false },
    { field: 'SrvD_Detalle', headerName: 'Detalle', flex: 1, editable: true },
    { field: 'instaladores', headerName: 'Instaladores', flex: 1, renderCell: (params) => <InstaladoresCell lstInstaladores={instaladoresByService} setInstaladores={setInstaladoresByService} instaladores={instaladores} idActual={params.row.id} /> },
    {
      field: 'DeleteButton',
      headerName: 'Delete',
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteRow(params.row)}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  const handleRowUpdate = useCallback((updatedRow: any, oldRow: any) => {
    const { SrvD_Cantidad, SrvD_ValorUnitario } = updatedRow;

    if (SrvD_Cantidad !== oldRow.SrvD_Cantidad || SrvD_ValorUnitario !== oldRow.SrvD_ValorUnitario) {
      const newIva = SrvD_ValorUnitario * SrvD_Cantidad * 0.19;
      const newTotal = SrvD_Cantidad * SrvD_ValorUnitario + newIva;
      updatedRow = {
        ...updatedRow,
        SrvD_Iva: newIva,
        SrvD_Total: newTotal,
      };
    }

    setDetalleServicio((prevDetalle) => prevDetalle.map((row) => (row.id === updatedRow.id ? updatedRow : row)));

    return updatedRow;
  }, []);

  const calculateTotal = useCallback(() => {
    return detalleServicio.reduce((acc, row) => acc + row.SrvD_Total, 0);
  }, [detalleServicio]);

  const handleSubmit = useCallback(async () => {
    const tempErrors = { Srv_FechaServicio: '', Srv_VehiculoMarca: '', Srv_VehiculoReferencia: '', Srv_VehiculoPlacaOSerial: '' };
    if (!servicio.Srv_FechaServicio) tempErrors.Srv_FechaServicio = 'Fecha requerida';
    if (!servicio.Srv_VehiculoMarca) tempErrors.Srv_VehiculoMarca = 'Marca requerida';
    if (!servicio.Srv_VehiculoReferencia) tempErrors.Srv_VehiculoReferencia = 'Referencia requerida';
    if (!servicio.Srv_VehiculoPlacaOSerial) tempErrors.Srv_VehiculoPlacaOSerial = 'Placa o serial requerido';

    setFormErrors(tempErrors);

    if (Object.values(tempErrors).some((error) => error)) {
      return;
    }

    if (!currentCliente) {
      NewNotification('Debes seleccionar un cliente', 'warning');
      return;
    }

    if (detalleServicio.length === 0) {
      NewNotification('Debes agregar al menos un producto al detalle', 'warning');
      return;
    }

    const DetalleServiceAdded = detalleServicio.map((row) => {
      const { instaladores, ...detalle } = row;
      return {
        SrvD_Pr_Id: parseInt(detalle.SrvD_Pr_Id),
        SrvD_Cantidad: parseInt(detalle.SrvD_Cantidad),
        SrvD_ValorUnitario: parseInt(detalle.SrvD_ValorUnitario),
        SrvD_Iva: detalle.SrvD_Iva,
        SrvD_Total: detalle.SrvD_Total,
        SrvD_Detalle: detalle.SrvD_Detalle,
        instaladores: instaladoresByService
          .filter((x) => x.idService === row.id)
          .map((inst: any) => ({
            SrvDI_In_Id: inst.SrvDI_In_Id,
            SrvDI_Valor: parseInt(inst.SrvDI_Valor),
            SrvDI_Detalle: inst.SrvDI_Detalle,
          })),
      };
    });

    const newServicio = {
      ...servicio,
      Srv_Total: calculateTotal(),
      Srv_Cl_Id: currentCliente.Cl_Id,
      Srv_Direccion: currentCliente.Cl_Direccion,
      Srv_Us_Id: user?.Us_Id,
      detalle: DetalleServiceAdded,
    };

    setLoadingCreate(true);    
    const response = await createServicio(newServicio);
    setLoadingCreate(false);
    if (response.isOk) {
      NewNotification('Servicio creado correctamente', 'success');
      onClose();
    } else {
      NewNotification(response.message || 'Error creando el servicio', 'error');
    }
  }, [detalleServicio, currentCliente, instaladoresByService]);

  return (
    <div>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                {currentCliente === null ? (
                  <Button variant='outlined' fullWidth size='large' color='primary' onClick={handleFindClient}>
                    Cliente <Search sx={{ marginLeft: 1 }} />
                  </Button>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={2}>
                      <IconButton sx={{ border: 'solid', borderColor: '#888', borderWidth: '1px', padding: '5px', marginTop: '2px' }} onClick={handleFindClient}>
                        <Edit />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} sm={10}>
                      <TextField label='Nombre del Cliente' value={currentCliente.Cl_Nombre} fullWidth size='small' disabled />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField label='Fecha Servicio' required error={!!formErrors.Srv_FechaServicio} helperText={formErrors.Srv_FechaServicio || ''} type='datetime-local' name='Srv_FechaServicio' value={servicio.Srv_FechaServicio} onChange={handleInputChange} fullWidth size='small' InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Es Domicilio</InputLabel>
                  <Select name='Srv_EsDomicilio' value={servicio.Srv_EsDomicilio || '0'} onChange={(e) => handleSelectChange(e as SelectChangeEvent<number>)} label='Es Domicilio'>
                    <MenuItem value={'0'}>No</MenuItem>
                    <MenuItem value={'1'}>Sí</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField label='Factura' name='Srv_Factura' value={servicio.Srv_Factura} onChange={handleInputChange} fullWidth size='small' />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField label='Marca del Vehículo' error={!!formErrors.Srv_VehiculoMarca} helperText={formErrors.Srv_VehiculoMarca || ''}  required name='Srv_VehiculoMarca' value={servicio.Srv_VehiculoMarca} onChange={handleInputChange} fullWidth size='small' InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField label='Referencia del Vehículo' error={!!formErrors.Srv_VehiculoReferencia} helperText={formErrors.Srv_VehiculoReferencia || ''} required name='Srv_VehiculoReferencia' value={servicio.Srv_VehiculoReferencia} onChange={handleInputChange} fullWidth size='small' InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField label='Placa o Serial del Vehículo' error={!!formErrors.Srv_VehiculoPlacaOSerial} helperText={formErrors.Srv_VehiculoPlacaOSerial || ''} required name='Srv_VehiculoPlacaOSerial' value={servicio.Srv_VehiculoPlacaOSerial} onChange={handleInputChange} fullWidth size='small' InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {currentCliente === null ? 
                ( <TextField label='Dirección' name='Srv_Direccion' value={servicio.Srv_Direccion} onChange={handleInputChange} fullWidth size='small' />) 
                : ( <TextField label='Dirección' name='Srv_Direccion' value={currentCliente.Cl_Direccion} onChange={handleInputChange} fullWidth size='small' />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label='Observaciones' name='Srv_Observaciones' value={servicio.Srv_Observaciones} onChange={handleInputChange} fullWidth size='small' />
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <hr style={{ color: '#aa44cc', width: '80%' }} />
            <Grid item xs={9} sm={9}>
              <FormControl fullWidth size='small'>
                <InputLabel>Producto</InputLabel>
                <Select onChange={(e) => handleAddDetalleService(e)} label='Producto'>
                  {productos.map((producto) => (
                    <MenuItem key={`cmb-${producto.Pr_Id}`} value={producto}>
                      {producto.Pr_Nombre} {producto.Un_Nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={3}>
              <TextField label='Total' value={`$${calculateTotal()}`} fullWidth size='small' disabled />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid rows={detalleServicio} columns={columns} processRowUpdate={handleRowUpdate} />
              </Box>
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='space-between' sx={{ marginTop: 2 }}>
            <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loadingCreate}>
              {loadingCreate ? <CircularProgress size={24} /> : 'Crear'}
            </Button>
          </Box>
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle>Buscar o Crear Cliente</DialogTitle>
        <DialogContent>
          <CreateCliente
            onClose={(data: any) => {
              if (!data.isTrusted) {
                setCurrentCliente(data);
              }
              setOpenCreate(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddServicio;

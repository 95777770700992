import React, { useState, useEffect, useCallback } from 'react';
import { getInventarios } from '../../api/inventarios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box } from '@mui/material';
import { Add, Update, Policy, Remove } from '@mui/icons-material';
import AddStock from './_AddStock';
import RemoveStock from './_RemoveStock';
import ViewInventario from './_ViewById';
import ModalMonitoreoInventario from './_ModalMonitoreoInventario';
import CreateInventario from './_Create'; // Importa la nueva modal para crear inventario
import { useAuth } from '../../context/auth';
import { AccessDenied } from '../../components/AccessDenied/Index';

const Inventarios = () => {
  const [inventarios, setInventarios] = useState([]);
  const [openCreate, setOpenCreate] = useState(false); // Estado para la modal de crear inventario
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openMonitoreo, setOpenMonitoreo] = useState(false);
  const [selectedInventario, setSelectedInventario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { permisos } = useAuth();
  const { Prm_Invent_Ver, Prm_Invent_Crear, Prm_Invent_Modificar } = permisos;

  useEffect(() => {
    fetchInventarios();
  }, []);

  const fetchInventarios = useCallback(async () => {
    setLoading(true);
    const response = await getInventarios();
    if (response.Success) {
      setInventarios(response.Data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const filteredInventarios = inventarios.filter((inventario: any) => inventario.Pr_Nombre.toLowerCase().includes(searchTerm.toLowerCase()) || inventario.Pr_Codigo.toLowerCase().includes(searchTerm.toLowerCase()));

  if (Prm_Invent_Ver === 0) {
    return <AccessDenied />;
  }

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_Invent_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Crear Inventario' sx={{ border: 'solid', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}

            <IconButton onClick={fetchInventarios} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
            <IconButton onClick={() => setOpenMonitoreo(true)} title='Monitoreo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Policy />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Producto</TableCell>
                  <TableCell>Valor Compra</TableCell>
                  <TableCell>Valor Venta</TableCell>
                  <TableCell>Stock</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInventarios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inventario: any) => (
                  <TableRow key={inventario.Inv_Id}>
                    <TableCell>{inventario.Inv_Id}</TableCell>
                    <TableCell>{inventario.Pr_Codigo}</TableCell>
                    <TableCell>
                      {inventario.Pr_Nombre} {inventario.Un_Nombre}
                    </TableCell>
                    <TableCell>${parseInt(inventario.Inv_ValorCompra).toLocaleString()}</TableCell>
                    <TableCell>${parseInt(inventario.Inv_ValorVenta).toLocaleString()}</TableCell>
                    <TableCell>
                      {Prm_Invent_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedInventario(inventario);
                            setOpenRemove(true);
                          }}>
                          <Remove />
                        </IconButton>
                      )}

                      {inventario.Inv_Stock}
                      {Prm_Invent_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedInventario(inventario);
                            setOpenAdd(true);
                          }}>
                          <Add />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredInventarios.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openAdd} onClose={() => setOpenAdd(false)}>
        <DialogTitle>Agregar Stock</DialogTitle>
        <DialogContent>
          <AddStock
            inventario={selectedInventario}
            onClose={() => {
              setOpenAdd(false);
              fetchInventarios();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openRemove} onClose={() => setOpenRemove(false)}>
        <DialogTitle>Eliminar Stock</DialogTitle>
        <DialogContent>
          <RemoveStock
            inventario={selectedInventario}
            onClose={() => {
              setOpenRemove(false);
              fetchInventarios();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>Ver Inventario</DialogTitle>
        <DialogContent>
          <ViewInventario inventario={selectedInventario} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      {openCreate && (
        <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
          <DialogTitle>Crear Inventario</DialogTitle>
          <DialogContent>
            <CreateInventario
              onClose={() => {
                setOpenCreate(false);
                fetchInventarios();
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {openMonitoreo && (
        <Dialog open={openMonitoreo} onClose={() => setOpenMonitoreo(false)} fullWidth maxWidth='lg'>
          <DialogTitle>Monitoreo de Inventario</DialogTitle>
          <DialogContent>
            <ModalMonitoreoInventario onClose={() => setOpenMonitoreo(false)} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Inventarios;

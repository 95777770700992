// src/pages/home/types.tsx

export interface EstadoServicio {
  id: number;
  texto: string;
  color: string;
  permiso: string | undefined;
}

export const estadosServicio: EstadoServicio[] = [
  { id: 1, permiso: undefined, texto: 'Creado', color: '#FFFFFF' }, // Blanco
  { id: 7, permiso: undefined, texto: 'Creado accesorios', color: '#38b6ff' }, // Gris
  { id: 2, permiso: undefined, texto: 'Despachado', color: '#0abc8d' }, // Verde
  { id: 3, permiso: undefined, texto: 'Finalizado', color: '#FFC300' }, // Amarillo
  { id: 4, permiso: "Prm_Credito_Cambiar", texto: 'Servicio en crédito', color: '#6D92AF' }, // Naranja
  { id: 5, permiso: undefined, texto: 'Pendiente de factura', color: '#CA6870' }, // Rojo
  { id: 6, permiso: undefined, texto: 'Cancelado', color: '#808080' }, // Gris
];

export interface DetalleServicio {
  SrvD_Pr_Id: string;
  SrvD_Cantidad: number;
  SrvD_ValorUnitario: number;
  SrvD_Iva: number;
  SrvD_Total: number;
  SrvD_Detalle: string;
  instaladores: Instalador[];
}

export interface Instalador {
  SrvDI_In_Id: string;
  SrvDI_Valor: number;
}

export interface Servicio {
  Srv_Id?: string;
  Srv_Cl_Id: string;
  Srv_Us_Id: string;
  Srv_FechaServicio: string;
  Srv_VehiculoMarca: string;
  Srv_VehiculoReferencia: string;
  Srv_VehiculoPlacaOSerial: string;
  Srv_Direccion: string;
  Srv_EsDomicilio: string;
  Srv_Observaciones: string;
  Srv_Factura: string;
  Srv_Estado?: string;
  Srv_Total?: number;
  detalle: DetalleServicio[];
}

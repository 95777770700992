import { UseApi } from '../utils/axios-pettitions';

export async function getUnidades() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('unidades', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener las unidades',
    };
  }
}

export async function getUnidadById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('unidades', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la unidad',
    };
  }
}

export async function createUnidad(unidad: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('unidades', 'POST', null, unidad);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear la unidad',
    };
  }
}

export async function updateUnidad(id: number, unidad: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('unidades', 'PUT', { id }, unidad);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar la unidad',
    };
  }
}

export async function deleteUnidad(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('unidades', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar la unidad',
    };
  }
}

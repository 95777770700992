import axios from 'axios';
import { Http_Response } from '../types';

export const UseApi = () => {
  const runApi = async (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', params?: any, data?: any) => {
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrls = `${apiUrl}/api/${url}`;
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    try {
      const response = await axios({
        url: apiUrls,
        method,
        params,
        data,
        headers,
      })
        .then((res) => {          
          return res.data as Http_Response;
        })
        .catch((err) => {
          console.log(err);
          if (axios.isAxiosError(err)) {
            if (err.response) {
              const resp = err.response.data;
              return resp as Http_Response;
            }
          }
          return { Message: 'Se ha presentado un error interno en el servidor de acceso', Data: null, Success: false };
        });

      return response;
    } catch (error) {
      
      console.log(error);
      return { Message: 'Se ha presentado un error interno en el servidor de acceso', Data: null, Success: false };
    }
  };
  return { runApi };
};

import React, { useState } from 'react';
import { addStock } from '../../api/inventarios';
import { Button, TextField, Box, CircularProgress } from '@mui/material';
import { useAuth } from '../../context/auth';

const AddStock = React.memo(({ inventario, onClose }: any) => {    
  const [stock, setStock] = useState({
    Inv_Stock: 0,
    Inv_ValorCompra: inventario.Inv_ValorCompra,
    Inv_ValorVenta: inventario.Inv_ValorVenta
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Inv_Stock: '',
    Inv_ValorCompra: '',
    Inv_ValorVenta: ''
  });
  const {user} = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStock((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Inv_Stock: '', Inv_ValorCompra: '', Inv_ValorVenta: '' };
    if (!stock.Inv_Stock || stock.Inv_Stock <= 0) tempErrors.Inv_Stock = 'La cantidad es requerida y debe ser mayor a 0';
    if (!stock.Inv_ValorCompra || stock.Inv_ValorCompra <= 0) tempErrors.Inv_ValorCompra = 'El valor de compra es requerido y debe ser mayor a 0';
    if (!stock.Inv_ValorVenta || stock.Inv_ValorVenta <= 0) tempErrors.Inv_ValorVenta = 'El valor de venta es requerido y debe ser mayor a 0';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate() || !user || !user.Us_Id) return;
    setLoading(true);
    await addStock(inventario.Inv_Id, stock, parseInt(user?.Us_Id))
    setLoading(false);
    onClose();
  };

return (
    <div>
        <form>
            <TextField label='Cantidad' name='Inv_Stock' value={stock.Inv_Stock} type='number'  onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Inv_Stock} helperText={errors.Inv_Stock} inputProps={{ min: 1 }} />
            <TextField label='Valor Compra' name='Inv_ValorCompra' value={stock.Inv_ValorCompra} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Inv_ValorCompra} helperText={errors.Inv_ValorCompra} />
            <TextField label='Valor Venta' name='Inv_ValorVenta' value={stock.Inv_ValorVenta} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Inv_ValorVenta} helperText={errors.Inv_ValorVenta} />
            <Box display='flex' justifyContent='space-between'>
                <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Agregar'}
                </Button>
                <Button onClick={onClose} variant='outlined' color='error' size='small'>
                    Cancelar
                </Button>
            </Box>
        </form>
    </div>
);
});

export default AddStock;

import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Box, CircularProgress, Dialog, DialogContent,Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { useAuth } from '../../context/auth';
import { Add, Close, History, Update } from '@mui/icons-material';
import { TransitionProps } from 'notistack';
import { getCreditosByClient } from '../../api/creditos';
import moment from 'moment';
import { Abonar } from './_Abonar';
import { History as HistoryModal } from './_History';

interface ViewCreditoProps {
  data: any;
  open: boolean;
  onClose: () => void;
}

const ViewCredito: React.FC<ViewCreditoProps> = ({ data, open, onClose }) => {
  const { permisos } = useAuth();
  const [loading, setLoading] = useState(false);
  const [openAbonar, setOpenAbonar] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [dataCredito, setDataCredito] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { Prm_Credito_Abonar } = permisos;

  useEffect(() => {
    if (open) {
      fetchCredito();
    }
  }, [open, data]);

  const fetchCredito = useCallback(() => {
    setLoading(true);
    getCreditosByClient(data.Cl_Id)
      .then((response) => {
        if (response.isOk) {
          setDataCredito(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setDataCredito, data]);

  const handleAbonar = () => {
    setOpenAbonar(true);
  };

  const handleHistory = () => {
    setOpenHistory(true);
  };

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onConfirmAbonar = () => {
    console.log('Abonar');
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Créditos de: {data ? data.Cl_Nombre : '...'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {loading ? (
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ marginTop: '5px' }}>
            <Grid container spacing={2} alignItems='center' mb={2}>
              <Grid item xs={12} sm={6} md={12}>
                {Prm_Credito_Abonar === 1 && (
                  <IconButton onClick={handleAbonar} title='Agregar Servicio' sx={{ border: 'solid', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                    <Add />
                  </IconButton>
                )}
                <IconButton onClick={handleHistory} title='Historial' sx={{ border: 'solid', borderColor: '#888', marginLeft: '3px', borderWidth: '1px', padding: '5px' }}>
                  <History />
                </IconButton>
                <IconButton onClick={fetchCredito} title='Historial' sx={{ border: 'solid', borderColor: '#888', marginLeft: '3px', borderWidth: '1px', padding: '5px' }}>
                  <Update />
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Servicio</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Valor Restante</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataCredito.map((credito: any) => (
                    <TableRow key={credito.Srv_Id}>
                      <TableCell scope='row'>{credito.Srv_Id}</TableCell>
                      <TableCell scope='row'>{moment(credito.Srv_FechaServicio).format('YYYY-MM-DD')}</TableCell>
                      <TableCell scope='row'>$ {parseInt(credito.Srv_Total)}</TableCell>
                      <TableCell scope='row'>$ {parseInt(credito.Srv_ValorRestante)}</TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination component='div' count={dataCredito.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
          </div>
        )}

        <Abonar open={openAbonar} onClose={() => setOpenAbonar(false)} data={data} />
        <HistoryModal open={openHistory} onClose={() => setOpenHistory(false)} data={data} />

      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ViewCredito);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

import React, { useState, useEffect } from 'react';
import { createInventario } from '../../api/inventarios';
import { getProductos } from '../../api/productos'; // Suponiendo que ya tienes una API para obtener productos
import { Button, TextField, Select, MenuItem, Box, CircularProgress } from '@mui/material';
import { useAuth } from '../../context/auth';
import { UseAlert } from '../../components/newAlert/NewAlert';

const CreateInventario = React.memo(({ onClose }: any) => {
  const [inventario, setInventario] = useState({
    Inv_Pr_Id: '',
    Inv_Stock: 0,
    Inv_ValorCompra: '',
    Inv_ValorVenta: '',
  });
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { NewNotification } = UseAlert();
  const [errors, setErrors] = useState({
    Inv_Pr_Id: '',
    Inv_Stock: '',
    Inv_ValorCompra: '',
    Inv_ValorVenta: '',
  });
  const { user } = useAuth();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const response = await getProductos();
    if (response.isOk) {
      setProducts(response.data);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInventario((prev) => ({ ...prev, [name as string]: value }));
  };

  const validate = () => {
    let tempErrors = { Inv_Pr_Id: '', Inv_Stock: '', Inv_ValorCompra: '', Inv_ValorVenta: '' };
    if (!inventario.Inv_Pr_Id) tempErrors.Inv_Pr_Id = 'El producto es requerido';
    if (!inventario.Inv_Stock || inventario.Inv_Stock <= 0) tempErrors.Inv_Stock = 'La cantidad es requerida y debe ser mayor a 0';
    if (!inventario.Inv_ValorCompra || parseFloat(inventario.Inv_ValorCompra) <= 0) tempErrors.Inv_ValorCompra = 'El valor de compra es requerido y debe ser mayor a 0';
    if (!inventario.Inv_ValorVenta || parseFloat(inventario.Inv_ValorVenta) <= 0) tempErrors.Inv_ValorVenta = 'El valor de venta es requerido y debe ser mayor a 0';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate() || !user || !user.Us_Id) return;
    setLoading(true);
    var result = await createInventario(inventario, parseInt(user.Us_Id));
    setLoading(false);
    if (result.Success) {
      onClose();
    } else {
      NewNotification(result.Message, 'error');
    }
  };

  if (!products.length)
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    );

  return (
    <div>
      <form>
        <Select
          size='small'
          name='Inv_Pr_Id'
          value={inventario.Inv_Pr_Id}
          onChange={handleChange}
          fullWidth
          displayEmpty
          error={!!errors.Inv_Pr_Id}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Seleccione un producto</em>;
            }
            const selectedProduct = products.find((product: any) => product.Pr_Id === selected);
            return (
              <>
                {selectedProduct?.Pr_Nombre} {selectedProduct?.Un_Nombre}
              </>
            );
          }}>
          <MenuItem disabled value=''>
            <em>Seleccione un producto</em>
          </MenuItem>
          {products.map((product: any) => (
            <MenuItem key={product.Pr_Id} value={product.Pr_Id}>
              {product.Pr_Nombre} {product.Un_Nombre}
            </MenuItem>
          ))}
        </Select>
        <TextField label='Cantidad' name='Inv_Stock' value={inventario.Inv_Stock} type='number' onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Inv_Stock} helperText={errors.Inv_Stock} inputProps={{ min: 1 }} />
        <TextField label='Valor Compra' name='Inv_ValorCompra' value={inventario.Inv_ValorCompra} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Inv_ValorCompra} helperText={errors.Inv_ValorCompra} />
        <TextField label='Valor Venta' name='Inv_ValorVenta' value={inventario.Inv_ValorVenta} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Inv_ValorVenta} helperText={errors.Inv_ValorVenta} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateInventario;

import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewCliente = React.memo(({ cliente, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label='Tipo documento' value={cliente.Cl_Ti_Id} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' />
        <TextField label='Documento' value={cliente.Cl_Documento} fullWidth disabled sx={{ marginBottom: '1rem' }} size='small' />
        <TextField label='Nombre' value={cliente.Cl_Nombre} fullWidth disabled sx={{ marginBottom: '1rem' }} size='small' />
        <TextField label='Teléfono' value={cliente.Cl_Telefono} fullWidth disabled sx={{ marginBottom: '1rem' }} size='small' />
        <TextField label='Correo' value={cliente.Cl_Correo} fullWidth disabled sx={{ marginBottom: '1rem' }} size='small' />
        <TextField label='Dirección' value={cliente.Cl_Direccion} fullWidth disabled sx={{ marginBottom: '1rem' }} size='small' />
        <Box display='flex' justifyContent='flex-end'>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewCliente;

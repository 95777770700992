import React, { useState } from 'react';
import { createInstalador } from '../../../api/instaladores';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const CreateInstalador = React.memo(({ onClose }: any) => {
  const [instalador, setInstalador] = useState({
    In_Nombre: '',
    In_Apellido: '',
    In_Documento: '',
    In_Telefono: '',
    In_Correo: '',
    In_Estado: 1,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    In_Nombre: '',
    In_Apellido: '',
    In_Documento: '',
    In_Telefono: '',
    In_Correo: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInstalador((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { In_Nombre: '', In_Apellido: '', In_Documento: '', In_Telefono: '', In_Correo: '' };
    if (!instalador.In_Nombre) tempErrors.In_Nombre = 'El nombre es requerido';
    if (!instalador.In_Apellido) tempErrors.In_Apellido = 'El apellido es requerido';
    if (!instalador.In_Documento) tempErrors.In_Documento = 'El documento es requerido';
    if (!instalador.In_Telefono) tempErrors.In_Telefono = 'El teléfono es requerido';
    if (!instalador.In_Correo) tempErrors.In_Correo = 'El correo es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createInstalador(instalador);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='In_Nombre' value={instalador.In_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.In_Nombre} helperText={errors.In_Nombre} />
        <TextField label='Apellido' name='In_Apellido' value={instalador.In_Apellido} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Apellido} helperText={errors.In_Apellido} />
        <TextField label='Documento' name='In_Documento' value={instalador.In_Documento} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Documento} helperText={errors.In_Documento} />
        <TextField label='Teléfono' name='In_Telefono' value={instalador.In_Telefono} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Telefono} helperText={errors.In_Telefono} />
        <TextField label='Correo' name='In_Correo' value={instalador.In_Correo} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Correo} helperText={errors.In_Correo} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateInstalador;

import React, { useState } from 'react';
import { createCategoria } from '../../../api/categorias';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const CreateCategoria = React.memo(({ onClose }: any) => {
  const [categoria, setCategoria] = useState({
    Ct_Nombre: '',
    Ct_Estado: 1,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Ct_Nombre: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCategoria((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Ct_Nombre: '' };
    if (!categoria.Ct_Nombre) tempErrors.Ct_Nombre = 'El nombre es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createCategoria(categoria);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Ct_Nombre' value={categoria.Ct_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Ct_Nombre} helperText={errors.Ct_Nombre} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateCategoria;

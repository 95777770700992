import { UseApi } from '../utils/axios-pettitions';

export async function getLiquidaciones(fechaInicio: string, fechaFin: string) {
    try {
        const {runApi} = UseApi();
        const response = await runApi('liquidacion', 'GET', {fechaInicio, fechaFin});
        
        if (!response.Success) {
            return {
                isOk: false,
                message: response.Message,
            };
        }
        return {
            isOk: true,
            data: response.Data,
        };
    } catch {
        return {
            isOk: false,
            message: 'Error al obtener las liquidaciones',
        };    
    }
}

export async function getLiquidacionesById(id: number) {
    try {
        const {runApi} = UseApi();
        const reponse = await runApi('liquidacion', 'GET', {id});
        if (!reponse.Success) {
            return {
                isOk: false,
                message: reponse.Message,
            };
        }
        return {
            isOk: true,
            data: reponse.Data,
        };
    } catch {
        return {
            isOk: false,
            message: 'Error al obtener la liquidación',
        };
    }
}

export async function updateLiquidaciones( id: number, liquidacion: any) {
    try {
      const { runApi } = UseApi();
      const response = await runApi('liquidacion', 'PUT', { detalleId: id }, liquidacion);
      if (!response.Success) {
        return {
          isOk: false,
          message: response.Message,
        };
      }
      return {
        isOk: true,
        data: response.Data,
      };
    } catch {
      return {
        isOk: false,
        message: 'Error al actualizar el servicio',
      };
    }
  }
  
  export async function updateEstadoLiquidaciones(id: number, estado: any) {
    try {
      const { runApi } = UseApi();
      const response = await runApi('liquidacion', 'PUT', { id, estado });
      if (!response.Success) {
        return {
          isOk: false,
          message: response.Message,
        };
      }
      return {
        isOk: true,
        data: response.Data,
      };
    } catch {
      return {
        isOk: false,
        message: 'Error al actualizar el estado de la liquidacion',
      };
    }
  }

  export async function deleteLiquidacion(id: number) {
    try {
      const { runApi } = UseApi();
      const response = await runApi('liquidacion', 'DELETE', { id });
      if (!response.Success) {
        return {
          isOk: false,
          message: response.Message,
        };
      }
      return {
        isOk: true,
        data: response.Data,
      };
    } catch {
      return {
        isOk: false,
        message: 'Error al eliminar la liquidacion',
      };
    }
  }

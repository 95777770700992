import { UseApi } from '../utils/axios-pettitions';

export async function getServicios() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los servicios',
    };
  }
}

export async function getServicioById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el servicio',
    };
  }
}

export async function getDetalleServicio(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'GET', { id, detalle: true });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el detalle del servicio',
    };
  }
}

export async function createServicio(servicio: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'POST', null, servicio);
    console.log(response);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el servicio',
    };
  }
}

export async function updateServicio(id: number, servicio: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'PUT', { id, action: 'update' }, servicio);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el servicio',
    };
  }
}

export async function updateEstadoServicio(id: number, estado: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'PUT', { id, action: 'updateEstado' }, { estado });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el estado del servicio',
    };
  }
}

export async function updateDetalleServicio(id: number, detalle: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('servicio', 'PUT', { id, action: 'updateDetalle' }, detalle);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el detalle del servicio',
    };
  }
}

import React, { useState } from 'react';
import { createUnidad } from '../../../api/unidades';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const CreateUnidad = React.memo(({ onClose }: any) => {
  const [unidad, setUnidad] = useState({
    Un_Nombre: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Un_Nombre: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUnidad((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Un_Nombre: '' };
    if (!unidad.Un_Nombre) tempErrors.Un_Nombre = 'El nombre es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createUnidad(unidad);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Un_Nombre' value={unidad.Un_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Un_Nombre} helperText={errors.Un_Nombre} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateUnidad;

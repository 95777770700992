import { UseApi } from '../utils/axios-pettitions';

export async function getCategorias() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('categoria', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener las categorías',
    };
  }
}

export async function getCategoriaById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('categoria', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la categoría',
    };
  }
}

export async function createCategoria(categoria: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('categoria', 'POST', null, categoria);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear la categoría',
    };
  }
}

export async function updateCategoria(id: number, categoria: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('categoria', 'PUT', { id }, categoria);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar la categoría',
    };
  }
}

export async function deleteCategoria(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('categoria', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar la categoría',
    };
  }
}

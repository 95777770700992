import { UseApi } from '../utils/axios-pettitions';

export async function getInstaladores() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('instaladores', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los instaladores',
    };
  }
}

export async function getInstaladorById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('instaladores', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el instalador',
    };
  }
}

export async function createInstalador(instalador: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('instaladores', 'POST', null, instalador);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el instalador',
    };
  }
}

export async function updateInstalador(id: number, instalador: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('instaladores', 'PUT', { id }, instalador);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el instalador',
    };
  }
}

export async function deleteInstalador(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('instaladores', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el instalador',
    };
  }
}

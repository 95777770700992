import React, { useState } from 'react';
import { createUsuario } from '../../../api/usuarios';
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material';

const CreateUsuario = React.memo(({ onClose, LstPerfiles }: any) => {
  const [usuario, setUsuario] = useState({
    Us_Nombre: '',
    Us_Usuario: '',
    Us_Contrasena: '',
    Us_Perfil_Id : null,
    Us_Estado: 1,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Us_Nombre: '',
    Us_Usuario: '',
    Us_Perfil_Id : '',
    Us_Contrasena: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUsuario((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Us_Nombre: '', Us_Usuario: '', Us_Contrasena: '', Us_Perfil_Id : '' };
    if (!usuario.Us_Nombre) tempErrors.Us_Nombre = 'El nombre es requerido';
    if (!usuario.Us_Usuario) tempErrors.Us_Usuario = 'El usuario es requerido';
    if (!usuario.Us_Contrasena) tempErrors.Us_Contrasena = 'La contraseña es requerida';
    if (!usuario.Us_Perfil_Id) tempErrors.Us_Perfil_Id = 'El perfil es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createUsuario(usuario);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Us_Nombre' value={usuario.Us_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Us_Nombre} helperText={errors.Us_Nombre} />
        <TextField label='Usuario' name='Us_Usuario' value={usuario.Us_Usuario} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Usuario} helperText={errors.Us_Usuario} />
        <TextField label='Perfil' name='Us_Perfil_Id' value={usuario.Us_Perfil_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Perfil_Id} helperText={errors.Us_Perfil_Id} select>
          {LstPerfiles.map((perfil: any) => (
            <MenuItem key={perfil.Pe_Id} value={perfil.Pe_Id}>
              {perfil.Pe_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField label='Contraseña' name='Us_Contrasena' type='password' value={usuario.Us_Contrasena} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Us_Contrasena} helperText={errors.Us_Contrasena} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateUsuario;

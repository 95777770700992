import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewInstalador = React.memo(({ instalador, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label="Nombre" value={instalador.In_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Apellido" value={instalador.In_Apellido} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Documento" value={instalador.In_Documento} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Teléfono" value={instalador.In_Telefono} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Correo" value={instalador.In_Correo} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewInstalador;

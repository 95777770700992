import React, { useState, useEffect } from 'react';
import { updateTipoIdentificacion } from '../../../api/tipoIdentificacion';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const EditTipoIdentificacion = React.memo(({ tipoIdentificacion, onClose }: any) => {
  const [formData, setFormData] = useState(tipoIdentificacion);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Ti_Nombre: '',
  });

  useEffect(() => {
    setFormData(tipoIdentificacion);
  }, [tipoIdentificacion]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Ti_Nombre: '' };
    if (!formData.Ti_Nombre) tempErrors.Ti_Nombre = 'El nombre es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await updateTipoIdentificacion(formData.Ti_Id, formData);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Ti_Nombre' value={formData.Ti_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Ti_Nombre} helperText={errors.Ti_Nombre} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditTipoIdentificacion;

import React, { useCallback, useEffect, useState } from 'react';
import { createCliente, getClienteByDocument } from '../../../api/clientes';
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material';
import { getTipoIdentificaciones } from '../../../api/tipoIdentificacion';
import { UseAlert } from '../../../components/newAlert/NewAlert';

const CreateCliente = React.memo(({ onClose, TipoIdentificacion }: any) => {
  const [lstTipoIdentificacion, setLstTipoIdentificacion] = useState(TipoIdentificacion || []);
  const [clInexistente, setClInexistente] = useState(false);
  const { NewNotification } = UseAlert();
  const [cliente, setCliente] = useState({
    Cl_Ti_Id: '',
    Cl_Documento: '',
    Cl_Nombre: '',
    Cl_Telefono: '',
    Cl_Correo: '',
    Cl_Direccion: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Cl_Ti_Id: '',
    Cl_Documento: '',
    Cl_Nombre: '',
    Cl_Telefono: '',
    Cl_Correo: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCliente((prev) => ({ ...prev, [name]: value }));
  };


  const handleChangeDoc = async (e: any) => {    
    const { name, value } = e.target;
    setLoading(true);
    const currentCl = await getClienteByDocument(value)
    if (currentCl && currentCl.data.Cl_Id) {
      setClInexistente(false);
      setCliente(currentCl.data);
      onClose(currentCl.data);
    } else {
      setClInexistente(true);
      setCliente((prev) => ({ ...prev, [name]: value }));
      NewNotification('Cliente no encontrado', 'warning');
    }
    setLoading(false);    
  };

  useEffect(() => {
    if (!TipoIdentificacion) {
      fetchTipoIdentificaciones();
    }
  }, []);

  const fetchTipoIdentificaciones = useCallback(async () => {
    setLoading(true);
    const response = await getTipoIdentificaciones();
    if (response.isOk) {
      setLstTipoIdentificacion(response.data);
    }
    setLoading(false);
  }, []);

  const validate = () => {
    let tempErrors = { Cl_Ti_Id: '', Cl_Nombre: '', Cl_Apellido: '', Cl_Documento: '', Cl_Telefono: '', Cl_Correo: '' };
    if (!cliente.Cl_Nombre) tempErrors.Cl_Nombre = 'El nombre es requerido';
    if (!cliente.Cl_Documento) tempErrors.Cl_Documento = 'El documento es requerido';
    if (!cliente.Cl_Telefono) tempErrors.Cl_Telefono = 'El teléfono es requerido';
    if (!cliente.Cl_Correo) tempErrors.Cl_Correo = 'El correo es requerido';
    if (!cliente.Cl_Ti_Id) tempErrors.Cl_Ti_Id = 'El tipo de identificación es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    const current = await createCliente(cliente);
    setLoading(false);
    onClose(current.data);
    setClInexistente(false)
  };

  return (
    <div>
      <form>

        <TextField disabled={loading} label='Documento' name='Cl_Documento'  onBlur={handleChangeDoc} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Cl_Documento} helperText={errors.Cl_Documento} />
        <TextField disabled={!clInexistente} label='Tipo Documento' name='Cl_Ti_Id' value={cliente.Cl_Ti_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Ti_Id} helperText={errors.Cl_Ti_Id} select>
          {lstTipoIdentificacion.map((tipoIdentificacion: any) => (
            <MenuItem key={tipoIdentificacion.Ti_Id} value={tipoIdentificacion.Ti_Id}>
              {tipoIdentificacion.Ti_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField disabled={!clInexistente} label='Nombre' name='Cl_Nombre' value={cliente.Cl_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Nombre} helperText={errors.Cl_Nombre} />
        <TextField disabled={!clInexistente} label='Teléfono' name='Cl_Telefono' value={cliente.Cl_Telefono} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Telefono} helperText={errors.Cl_Telefono} />
        <TextField disabled={!clInexistente} label='Correo' name='Cl_Correo' value={cliente.Cl_Correo} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Correo} helperText={errors.Cl_Correo} />
        <TextField disabled={!clInexistente} label='Dirección' name='Cl_Direccion' value={cliente.Cl_Direccion} onChange={handleChange} fullWidth sx={{ marginBottom: '1rem' }} size='small' />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateCliente;

import React, { useState } from 'react';
import { createPerfiles } from '../../../api/usuarios';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

export const CreatePerfil = React.memo(({ onClose }: any) => {
  const [usuario, setUsuario] = useState({
    Pe_Nombre: '',
    Pe_Describ: '',
    Pe_Estado: 1,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Pe_Nombre: '',
    Pe_Describ: '',    
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUsuario((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Pe_Nombre: '', Pe_Describ: '' };
    if (!usuario.Pe_Nombre) tempErrors.Pe_Nombre = 'El nombre es requerido';
    if (!usuario.Pe_Describ) tempErrors.Pe_Describ = 'El usuario es requerido';    
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    console.log(usuario);
    await createPerfiles(usuario);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Pe_Nombre' value={usuario.Pe_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Pe_Nombre} helperText={errors.Pe_Nombre} />
        <TextField label='Descripción' name='Pe_Describ' value={usuario.Pe_Describ} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Pe_Describ} helperText={errors.Pe_Describ} />        
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});


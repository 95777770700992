import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, IconButton, Chip, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState, useCallback, useRef } from 'react';
import { UseAlert } from '../../components/newAlert/NewAlert';

export const InstaladoresCell = ({ instaladores, lstInstaladores, setInstaladores, idActual }: { instaladores: any[]; lstInstaladores: any[]; setInstaladores: any; idActual: string }) => {
  const [formData, setFormData] = useState({ SrvDI_In_Id: '', SrvDI_Valor: '', SrvDI_Detalle: '', nombre: '' });
  const [formErrors, setFormErrors] = useState({ SrvDI_In_Id: false, SrvDI_Valor: false });
  const [dialogAdd, setDialogAdd] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const { NewNotification } = UseAlert();
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleInstaladorChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      const selectedInstalador = instaladores.find((inst) => inst.In_Id === value);
      if (selectedInstalador) {
        setFormData((prevData) => ({
          ...prevData,
          SrvDI_In_Id: selectedInstalador.In_Id,
          nombre: selectedInstalador.In_Nombre,
        }));
      }
    },
    [instaladores]
  );

  const handleSave = () => {
    if (lstInstaladores.some((x) => x.SrvDI_In_Id === formData.SrvDI_In_Id && x.idService === idActual)) {
      NewNotification(`El instalador ya fue agregado`, 'warning');
      return;
    }
    const errors = {
      SrvDI_In_Id: formData.SrvDI_In_Id === '',
      SrvDI_Valor: formData.SrvDI_Valor === '',
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    const newInstalador = {
      id: Date.now(),
      idService: idActual,
      SrvDI_In_Id: formData.SrvDI_In_Id,
      SrvDI_Valor: formData.SrvDI_Valor,
      SrvDI_Detalle: formData.SrvDI_Detalle,
      nombre: formData.nombre,
    };

    setInstaladores((prevInstaladores: any[]) => [...prevInstaladores, newInstalador]);
    setDialogAdd(false);
    setFormData({ SrvDI_In_Id: '', SrvDI_Valor: '', SrvDI_Detalle: '', nombre: '' });
  };

  const handleCancel = () => {
    setDialogAdd(false);
    setFormData({ SrvDI_In_Id: '', SrvDI_Valor: '', SrvDI_Detalle: '', nombre: '' });
    setFormErrors({ SrvDI_In_Id: false, SrvDI_Valor: false });
  };

  const handleDelete = (id: string) => {
    setInstaladores((prevInstaladores: any[]) => prevInstaladores.filter((instalador) => instalador.id !== id));
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (scrollContainerRef.current) {
      isDraggingRef.current = true;
      startXRef.current = e.pageX - scrollContainerRef.current.offsetLeft;
      scrollLeftRef.current = scrollContainerRef.current.scrollLeft;
    }
  };

  const handleMouseLeave = () => {
    isDraggingRef.current = false;
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDraggingRef.current || !scrollContainerRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = x - startXRef.current;
    scrollContainerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  return (
    <>
      <div
        ref={scrollContainerRef}
        style={{
          width: '100%',
          marginTop: 5,
          overflowX: 'auto',
          cursor: isDraggingRef.current ? 'grabbing' : 'grab',
          whiteSpace: 'nowrap',
        }}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}>
        <Box display='flex' flexWrap='nowrap' mb={1} sx={{ paddingBottom: 1 }}>
          {lstInstaladores
            .filter((x) => x.idService === idActual)
            .map((instalador) => (
              <Chip
                key={instalador.id}
                label={instalador.nombre}
                variant='outlined'
                onDelete={() => {
                  handleDelete(instalador.id);
                }}
                sx={{ m: 0.5, maxWidth: '100%' }}
              />
            ))}
          <IconButton
            size='small'
            color='primary'
            aria-label='add'
            onClick={() => {
              setDialogAdd(true);
            }}
            sx={{ alignSelf: 'flex-start' }}>
            <AddIcon />
          </IconButton>
        </Box>
      </div>

      <Dialog open={dialogAdd} onClose={() => setDialogAdd(false)}>
        <DialogTitle>Agregar instalador</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth size='small' error={formErrors.SrvDI_In_Id} sx={{ marginTop: 3 }}>
                <InputLabel>Instalador</InputLabel>
                <Select name='SrvDI_In_Id' value={formData.SrvDI_In_Id} onChange={handleInstaladorChange} label='Instalador'>
                  {instaladores.map((instalador) => (
                    <MenuItem key={instalador.In_Id} value={instalador.In_Id}>
                      {instalador.In_Nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField label='Valor' name='SrvDI_Valor' type='number' value={formData.SrvDI_Valor} onChange={handleInputChange} fullWidth size='small' error={formErrors.SrvDI_Valor} helperText={formErrors.SrvDI_Valor ? 'Campo requerido' : ''} />
            </Grid>
            <Grid item xs={12}>
              <TextField label='Detalle' name='SrvDI_Detalle' value={formData.SrvDI_Detalle} onChange={handleInputChange} fullWidth size='small' />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
          <Button onClick={handleSave} variant='outlined' size='small'>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

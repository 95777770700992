// SideNavBarLayout.tsx
import { Drawer, List, ListItemText, AppBar, Menu, MenuItem, Toolbar, Typography, Box, CssBaseline, useTheme, IconButton, useMediaQuery, ListItemIcon, ListItemButton, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import routes from './app-routes';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './context/auth';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#1076b5',
    color: '#fff',
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

const SideNavBarLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const { user, signOut, permisos } = useAuth();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSession = () => {
    setAnchorEl(null);
    signOut();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position='fixed' sx={{ zIndex: theme.zIndex.drawer + 100, background: '#1076b5', width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`, ml: isMobile ? 0 : `${drawerWidth}px` }}>
        <Toolbar>
          {isMobile && (
            <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
          )}

          {!isMobile && (
            <Typography variant='h6' noWrap component='div'>
              SafetySound
            </Typography>
          )}

          {/* Agregar esto al final de la barra de herramientas */}
          <Box sx={{ flexGrow: 1 }} />
          {!isMobile && (
            <Typography variant='inherit' noWrap component='div'>
              {user?.Us_Nombre}
            </Typography>
          )}
          <IconButton color='inherit' onClick={handleClick}>
            <Avatar>{user?.Us_Nombre[0]}</Avatar>
          </IconButton>
          <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {/* <MenuItem onClick={handleClose}>Mi cuenta</MenuItem> */}
            <MenuItem onClick={handleCloseSession}>Cerrar sesión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: drawerWidth },
        }}>
        <img src={'images/fondoWhite.png'} alt='logo' style={{ height: '80px', marginRight: 'auto', marginTop: isMobile ? 50 : 10, marginLeft: 'auto', marginBottom: 10 }} />
        <List>
          {routes.map((route) => {
            if(permisos[route.permission]===0) return null;
            
            return (
              <ListItemButton
                key={route.path}
                component={Link}
                to={route.path}
                sx={{
                  backgroundColor: location.pathname === route.path ? '#c21b4e' : '#079de4', // Cambia esto al color que prefieras
                }}> 
                <ListItemIcon>
                  <MenuIndicator />
                </ListItemIcon>
                <ListItemText primary={route.name} sx={{ color: '#fff' }} />
              </ListItemButton> 
            );
          })}
        </List>
      </StyledDrawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3, ml: isMobile ? 0 : `${drawerWidth}px`, mt: '64px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default SideNavBarLayout;

const MenuIndicator = styled('div')({
  width: '10px',
  height: '10px',
  backgroundColor: '#fff',
  marginRight: '25px',
});

import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewInventario = React.memo(({ inventario, onClose }: any) => {
return (
    <div>
        <form>
            <TextField label="Producto" value={inventario.Pr_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
            <TextField label="Stock" value={`$${inventario.Inv_Stock.toLocaleString()}`} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
            <TextField label="Valor Compra" value={`$${parseInt(inventario.Inv_ValorCompra).toLocaleString()}`} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
            <TextField label="Valor Venta" value={`$${parseInt(inventario.Inv_ValorVenta).toLocaleString()}`} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={onClose} variant="outlined" color="error" size="small">
                    Cerrar
                </Button>
            </Box>
        </form>
    </div>
);
});

export default ViewInventario;

import React, { useState, useEffect, useCallback } from 'react';
import { getTipoIdentificaciones, deleteTipoIdentificacion } from '../../../api/tipoIdentificacion';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box } from '@mui/material';
import { Edit, Delete, Visibility, Add, Update } from '@mui/icons-material';
import CreateTipoIdentificacion from './_Create';
import EditTipoIdentificacion from './_Edit';
import ViewTipoIdentificacion from './_ViewById';
import { ConfirmDelete } from '../../../components/ConfirmDelete';
import { useAuth } from '../../../context/auth';

const TipoIdentificacion = () => {
  const [tipoIdentificaciones, setTipoIdentificaciones] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedTipoIdentificacion, setSelectedTipoIdentificacion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { permisos } = useAuth();
  const { Prm_Config_Tip_Id_Crear, Prm_Config_Tip_Id_Modificar, Prm_Config_Tip_Id_Eliminar } = permisos;

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteTipoIdentificacion(deleteId);
      fetchTipoIdentificaciones();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchTipoIdentificaciones();
  }, []);

  const fetchTipoIdentificaciones = useCallback(async () => {
    setLoading(true);
    const response = await getTipoIdentificaciones();
    if (response.isOk) {
      setTipoIdentificaciones(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const filteredTipoIdentificaciones = tipoIdentificaciones.filter((tipoIdentificacion: any) => tipoIdentificacion.Ti_Nombre.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_Config_Tip_Id_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Nuevo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}
            <IconButton onClick={fetchTipoIdentificaciones} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTipoIdentificaciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tipoIdentificacion: any) => (
                  <TableRow key={tipoIdentificacion.Ti_Id}>
                    <TableCell>{tipoIdentificacion.Ti_Id}</TableCell>
                    <TableCell>{tipoIdentificacion.Ti_Nombre}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelectedTipoIdentificacion(tipoIdentificacion);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                      {Prm_Config_Tip_Id_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedTipoIdentificacion(tipoIdentificacion);
                            setOpenEdit(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_Config_Tip_Id_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(tipoIdentificacion.Ti_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredTipoIdentificaciones.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle>Crear Tipo de Identificación</DialogTitle>
        <DialogContent>
          <CreateTipoIdentificacion
            onClose={() => {
              setOpenCreate(false);
              fetchTipoIdentificaciones();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Editar Tipo de Identificación</DialogTitle>
        <DialogContent>
          <EditTipoIdentificacion
            tipoIdentificacion={selectedTipoIdentificacion}
            onClose={() => {
              setOpenEdit(false);
              fetchTipoIdentificaciones();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>Ver Tipo de Identificación</DialogTitle>
        <DialogContent>
          <ViewTipoIdentificacion tipoIdentificacion={selectedTipoIdentificacion} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar este tipo de identificación?' />
    </div>
  );
};

export default TipoIdentificacion;

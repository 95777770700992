import { Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './pages/Login';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route path='/login' element={<LoginForm />} />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
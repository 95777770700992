import { UseApi } from '../utils/axios-pettitions';

export async function getTipoIdentificaciones() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tipoidentificacion', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los tipos de identificación',
    };
  }
}

export async function getTipoIdentificacionById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tipoidentificacion', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el tipo de identificación',
    };
  }
}

export async function createTipoIdentificacion(tipoIdentificacion: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tipoidentificacion', 'POST', null, tipoIdentificacion);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el tipo de identificación',
    };
  }
}

export async function updateTipoIdentificacion(id: number, tipoIdentificacion: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tipoidentificacion', 'PUT', { id }, tipoIdentificacion);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el tipo de identificación',
    };
  }
}

export async function deleteTipoIdentificacion(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tipoidentificacion', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el tipo de identificación',
    };
  }
}

import React, { useState, useEffect, useCallback } from 'react';
import { getPerfiles, deletePerfiles } from '../../../api/usuarios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box } from '@mui/material';
import { Edit, Delete, Add, Update, Security } from '@mui/icons-material';
import { ConfirmDelete } from '../../../components/ConfirmDelete';
import { useAuth } from '../../../context/auth';
import { Permisos } from './_Permisos';
import { CreatePerfil } from './_CreatePerfiles';
import { EditPerfil } from './_EditPerfiles';

export const Perfiles = React.memo(() => {
  const [usuarios, setUsuarios] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openPermisos, setOpenPermisos] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);  
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { permisos } = useAuth();
  const { Prm_Config_Usr_Crear, Prm_Config_Usr_Modificar, Prm_Config_Usr_Eliminar } = permisos;

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deletePerfiles(deleteId);
      fetchUsuarios();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = useCallback(async () => {
    setLoading(true);
    const response = await getPerfiles();
    if (response.isOk) {
      setUsuarios(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const filteredUsuarios = usuarios.filter((usuario: any) => usuario.Pe_Nombre.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_Config_Usr_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Nuevo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}
            <IconButton onClick={fetchUsuarios} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsuarios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((usuario: any) => (
                  <TableRow key={usuario.Pe_Id}>
                    <TableCell>{usuario.Pe_Id}</TableCell>
                    <TableCell>{usuario.Pe_Nombre}</TableCell>
                    <TableCell>{usuario.Pe_Estado ? 'Activo' : 'Inactivo'}</TableCell>
                    <TableCell>      
                      {Prm_Config_Usr_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedUsuario(usuario);
                            setOpenEdit(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_Config_Usr_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(usuario.Pe_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => {
                          setSelectedUsuario(usuario);
                          setOpenPermisos(true);
                        }}>
                        <Security />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredUsuarios.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle>Crear Perfil</DialogTitle>
        <DialogContent>
          <CreatePerfil
            onClose={() => {
              setOpenCreate(false);
              fetchUsuarios();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Editar Perfil</DialogTitle>
        <DialogContent>
          <EditPerfil
            usuario={selectedUsuario}
            onClose={() => {
              setOpenEdit(false);
              fetchUsuarios();
            }}
          />
        </DialogContent>
      </Dialog>

      <Permisos open={openPermisos} onClose={() => setOpenPermisos(false)} currentData={selectedUsuario} />

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar este perfil?' />
    </div>
  );
});

import { UseApi } from '../utils/axios-pettitions';

export async function getProductos() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('productos', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los productos',
    };
  }
}

export async function getProductoById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('productos', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el producto',
    };
  }
}

export async function createProducto(producto: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('productos', 'POST', null, producto);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el producto',
    };
  }
}

export async function updateProducto(id: number, producto: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('productos', 'PUT', { id }, producto);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el producto',
    };
  }
}

export async function deleteProducto(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('productos', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el producto',
    };
  }
}

import React, { useState } from 'react';
import { removeStock } from '../../api/inventarios';
import { Button, TextField, Box, CircularProgress } from '@mui/material';
import { useAuth } from '../../context/auth';

const RemoveStock = React.memo(({ inventario, onClose }: any) => {
  const [stock, setStock] = useState({
    Inv_Stock: 0
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Inv_Stock: ''
  });
  const {user} = useAuth();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStock((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Inv_Stock: '' };
    if (!stock.Inv_Stock || stock.Inv_Stock <= 0) tempErrors.Inv_Stock = 'La cantidad es requerida y debe ser mayor a 0';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate() || !user || !user.Us_Id) return;
    setLoading(true);
    await removeStock(inventario.Inv_Id, stock, parseInt(user?.Us_Id)); 
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Cantidad' name='Inv_Stock' value={stock.Inv_Stock} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Inv_Stock} helperText={errors.Inv_Stock} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Eliminar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default RemoveStock;

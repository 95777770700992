import React, { useState, useEffect, useCallback } from 'react';
import { getLiquidaciones, deleteLiquidacion, updateEstadoLiquidaciones } from '../../api/liquidaciones';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box, MenuItem, Select, InputLabel, FormControl, Grid, SelectChangeEvent } from '@mui/material';
import { Delete, Visibility, Update } from '@mui/icons-material';
import { estadoLiquidacion } from './types';
import EditLiquidacion from './_Edit';
import ViewLiquidacion from './_ViewById';
import { ConfirmDelete } from '../../components/ConfirmDelete';
import moment from 'moment';
import { useAuth } from '../../context/auth';
import { AccessDenied } from '../../components/AccessDenied/Index';

const Liquidaciones = () => {
  const [liquidaciones, setLiquidaciones] = useState<any[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedLiquidacion, setSelectedLiquidacion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [estadoFilter, setEstadoFilter] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loadingEstado, setLoadingEstado] = useState<number | null>(null);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const { permisos } = useAuth();
  const { Prm_Liquid_Ver, Prm_Liquid_Eliminar, Prm_Liquid_Camb_Estado } = permisos;

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleEstadoFilterChange = useCallback((e: SelectChangeEvent<string[]>) => {
    setEstadoFilter(e.target.value as string[]);
  }, []);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  }, []);

  const handleEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteLiquidacion(deleteId);
      fetchLiquidaciones();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchLiquidaciones();
  }, []);

  const fetchLiquidaciones = useCallback(async () => {
    setLoading(true);
    const response = await getLiquidaciones(moment().subtract(30, 'days').format(), moment().format());
    if (response.isOk && response.data) {
      setLiquidaciones(response.data);
    } else {
      setLiquidaciones([]); // En caso de error o datos indefinidos, establecer un arreglo vacío
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleEstadoChange = useCallback(async (id: number, newEstado: string) => {
    setLoadingEstado(id);
    const response = await updateEstadoLiquidaciones(id, parseInt(newEstado));
    setLoadingEstado(null);
    if (response.isOk) {
      setLiquidaciones((prevLiqui) => prevLiqui.map((liquidacion: any) => (liquidacion.SrvDI_Id === id ? { ...liquidacion, SrvDI_Estado: newEstado } : liquidacion)));
    }
  }, []);

  const filteredLiquidaciones = liquidaciones.filter((liquidacion: any) => {
    const liquidacionFechaCreacion = moment(liquidacion.SrvDl_FechaCreacion).startOf('day');
    const startDateNormalized = startDate ? moment(startDate).startOf('day') : null;
    const endDateNormalized = endDate ? moment(endDate).endOf('day') : null;

    const matchesSearchTerm = liquidacion.In_Nombre.toLowerCase().includes(searchTerm.toLowerCase()) || liquidacion.In_Apellido.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesEstadoFilter = estadoFilter.length === 0 || estadoFilter.includes(liquidacion.SrvDI_Estado.toString());
    const matchesStartDate = startDateNormalized === null || liquidacionFechaCreacion.isSameOrAfter(startDateNormalized);
    const matchesEndDate = endDateNormalized === null || liquidacionFechaCreacion.isSameOrBefore(endDateNormalized);

    return matchesSearchTerm && matchesEstadoFilter && matchesStartDate && matchesEndDate;
  });

  const getEstadoText = (id: string) => {
    const estado = estadoLiquidacion.find((e) => e.id.toString() === id);
    return estado ? estado.texto : 'Todos';
  };

  const calculateTotalValue = (liquidaciones: any[]) => {
    return liquidaciones.reduce((total, liquidacion) => {
      const valor = parseFloat(liquidacion.SrvDI_Valor) || 0;
      return total + valor;
    }, 0);
  };

  if (Prm_Liquid_Ver === 0) {
    return <AccessDenied />;
  }

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Grid container spacing={2} alignItems='center' mb={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>Estado</InputLabel>
                <Select
                  multiple
                  value={estadoFilter}
                  onChange={handleEstadoFilterChange}
                  label='Estado'
                  renderValue={(selected) => {
                    // Mostrar los nombres de los estados seleccionados
                    return selected.map((id) => getEstadoText(id)).join(', ');
                  }}>
                  {estadoLiquidacion.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id.toString()}>
                      {estado.texto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Fecha de creacion' type='date' value={startDate || ''} onChange={handleStartDateChange} InputLabelProps={{ shrink: true }} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Fecha de finalizacion' type='date' value={endDate || ''} onChange={handleEndDateChange} InputLabelProps={{ shrink: true }} fullWidth size='small' />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <IconButton onClick={fetchLiquidaciones} title='Actualizar' sx={{ border: 'solid', borderColor: '#888', marginLeft: '3px', borderWidth: '1px', padding: '5px' }}>
                <Update />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Servicio</TableCell>
                  <TableCell>Trabajador</TableCell>
                  <TableCell>Fecha de creacion</TableCell>
                  <TableCell>Fecha de finalizacion</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Fecha de liquidacion</TableCell>
                  <TableCell>Placa/Serie</TableCell>
                  <TableCell>Detalle</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLiquidaciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((liquidacion: any) => (
                  <TableRow key={liquidacion.SrvDI_Id}>
                    <TableCell>{liquidacion.SrvD_Srv_Id}</TableCell>
                    <TableCell>
                      {liquidacion.In_Nombre} {liquidacion.In_Apellido}
                    </TableCell>
                    <TableCell>{moment(liquidacion.SrvDl_FechaCreacion).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{liquidacion.SrvDl_FechaFinalizacion && moment(liquidacion.SrvDl_FechaFinalizacion).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>
                      {loadingEstado === liquidacion.SrvDI_Id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Select disabled={Prm_Liquid_Camb_Estado ===0} value={liquidacion.SrvDI_Estado.toString()} style={{ backgroundColor: estadoLiquidacion.find((estado) => estado.id === parseInt(liquidacion.SrvDI_Estado))?.color }} onChange={(e) => handleEstadoChange(liquidacion.SrvDI_Id, e.target.value as string)} fullWidth size='small'>
                          {estadoLiquidacion.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id.toString()}>
                              {estado.texto}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>{liquidacion.SrvDI_FechaLiquidacion && moment(liquidacion.SrvDI_FechaLiquidacion).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{liquidacion.Srv_VehiculoPlacaOSerial}</TableCell>
                    <TableCell>{liquidacion.SrvDI_Detalle}</TableCell>
                    <TableCell>{liquidacion.SrvDI_Valor}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelectedLiquidacion(liquidacion);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                      {Prm_Liquid_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(liquidacion.SrvDI_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {/* Fila para el total */}
                <TableRow>
                  <TableCell colSpan={5} align='right'>
                    <strong>Total:</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{calculateTotalValue(filteredLiquidaciones)}</strong>
                  </TableCell>
                  <TableCell colSpan={3} />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredLiquidaciones.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Editar liquidacion</DialogTitle>
        <DialogContent>
          <EditLiquidacion
            liquidacion={selectedLiquidacion}
            onClose={() => {
              setOpenEdit(false);
              fetchLiquidaciones();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>Ver liquidacion</DialogTitle>
        <DialogContent>
          <ViewLiquidacion liquidacion={selectedLiquidacion} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar esta liquidacion?' />
    </div>
  );
};

export default Liquidaciones;

import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewUsuario = React.memo(({ usuario, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label="Nombre" value={usuario.Us_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Usuario" value={usuario.Us_Usuario} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Estado" value={usuario.Us_Estado ? 'Activo' : 'Inactivo'} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewUsuario;

import React, { useState } from 'react';
import { createTipoIdentificacion } from '../../../api/tipoIdentificacion';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const CreateTipoIdentificacion = React.memo(({ onClose }: any) => {
  const [tipoIdentificacion, setTipoIdentificacion] = useState({
    Ti_Nombre: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Ti_Nombre: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTipoIdentificacion((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Ti_Nombre: '' };
    if (!tipoIdentificacion.Ti_Nombre) tempErrors.Ti_Nombre = 'El nombre es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createTipoIdentificacion(tipoIdentificacion);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Ti_Nombre' value={tipoIdentificacion.Ti_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Ti_Nombre} helperText={errors.Ti_Nombre} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateTipoIdentificacion;

import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import type { User, AuthContextType } from '../types';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [perfil, setPerfil] = useState<User>();
  const [permisos, setPermisos] = useState<User>();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   (async function () {
  //     const result = await getUser();
  //     if (result.isOk) {        
  //       setUser(result.data.user);
  //       setPerfil(result.data.perfil);
  //       setPermisos(result.data.permisos);
  //     }
  //     setLoading(false);
  //   })();
  // }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data.user);
      setPerfil(result.data.perfil);
      setPermisos(result.data.permisos);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, perfil, permisos, signIn, signOut, loading,  }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }

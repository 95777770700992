// Content.tsx
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './app-routes';
import SideNavBarLayout from './SideNavBarLayout';
import Footer from './components/footer/Footer';

export default function Content() {
  return (
    <SideNavBarLayout>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path='*' element={<Navigate to='/home' />} />
      </Routes>
    </SideNavBarLayout>
  );
}

import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewProducto = React.memo(({ producto, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label="Nombre" value={producto.Pr_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <TextField label="Código" value={producto.Pr_Codigo} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Categoría" value={producto.Pr_Ct_Id} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Unidad" value={producto.Pr_Un_Id} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <TextField label="Estado" value={producto.Pr_Estado} fullWidth disabled sx={{ marginBottom: '1rem' }} size="small" />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewProducto;

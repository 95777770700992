import { UseApi } from '../utils/axios-pettitions';

export async function getClientes() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los clientes',
    };
  }
}

export async function getClienteById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el cliente',
    };
  }
}

export async function getClienteByDocument(document: string) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes/getClientesByDocument', 'GET', { document });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el cliente',
    };
  }
}

export async function createCliente(cliente: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes', 'POST', null, cliente);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el cliente',
    };
  }
}

export async function updateCliente(id: number, cliente: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes', 'PUT', { id }, cliente);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el cliente',
    };
  }
}

export async function deleteCliente(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('clientes', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el cliente',
    };
  }
}
import React, { useState, useEffect } from 'react';
import { updateInstalador } from '../../../api/instaladores';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const EditInstalador = React.memo(({ instalador, onClose }: any) => {
  const [formData, setFormData] = useState(instalador);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    In_Nombre: '',
    In_Apellido: '',
    In_Documento: '',
    In_Telefono: '',
    In_Correo: '',
  });

  useEffect(() => {
    setFormData(instalador);
  }, [instalador]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { In_Nombre: '', In_Apellido: '', In_Documento: '', In_Telefono: '', In_Correo: '' };
    if (!formData.In_Nombre) tempErrors.In_Nombre = 'El nombre es requerido';
    if (!formData.In_Apellido) tempErrors.In_Apellido = 'El apellido es requerido';
    if (!formData.In_Documento) tempErrors.In_Documento = 'El documento es requerido';
    if (!formData.In_Telefono) tempErrors.In_Telefono = 'El teléfono es requerido';
    if (!formData.In_Correo) tempErrors.In_Correo = 'El correo es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await updateInstalador(formData.In_Id, formData);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='In_Nombre' value={formData.In_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.In_Nombre} helperText={errors.In_Nombre} />
        <TextField label='Apellido' name='In_Apellido' value={formData.In_Apellido} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Apellido} helperText={errors.In_Apellido} />
        <TextField label='Documento' name='In_Documento' value={formData.In_Documento} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Documento} helperText={errors.In_Documento} />
        <TextField label='Teléfono' name='In_Telefono' value={formData.In_Telefono} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Telefono} helperText={errors.In_Telefono} />
        <TextField label='Correo' name='In_Correo' value={formData.In_Correo} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.In_Correo} helperText={errors.In_Correo} />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditInstalador;

import { UseApi } from '../utils/axios-pettitions';

export async function getCreditos() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('credito', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los instaladores',
    };
  }
}

export async function getCreditosByClient(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('credito', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los instaladores',
    };
  }
}

export async function abonarCredito(data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('credito/abonar', 'POST', null,  data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los instaladores',
    };
  }
}


export async function getCreditoHistory(id: number) {
    try {
      const { runApi } = UseApi();
      const response = await runApi('credito/historial', 'GET', { id });
      if (!response.Success) {
        return {
          isOk: false,
          message: response.Message,
        };
      }
      return {
        isOk: true,
        data: response.Data,
      };
    } catch {
      return {
        isOk: false,
        message: 'Error al obtener los instaladores',
      };
    }
  }
  

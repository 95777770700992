import { useCallback, useEffect, useState } from 'react';
import { getCreditos } from '../../api/creditos';
import { Box, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import ViewCredito from './_ViewCreditos';

const Index = () => {
  const [creditos, setCreditos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCreditos();
  }, []);

  const fetchCreditos = useCallback(() => {
    getCreditos()
      .then((response) => {
        if (response.isOk) {
          setCreditos(response.data);
        } else {
          console.error(response.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setCreditos]);

  const filteredServicios = creditos.filter((servicio: any) => {
    const matchesSearchTerm = servicio.Cl_Nombre.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesSearchTerm;
  });

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleViewCredito = useCallback(
    (credito: any) => {
      setSelectedItem(credito);
      setOpenView(true);
    },
    [setSelectedItem]
  );

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Grid container spacing={2} alignItems='center' mb={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServicios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((credito: any) => (
                  <TableRow key={credito.Cl_Id}>
                    <TableCell scope='row'>{credito.Cl_Nombre}</TableCell>
                    <TableCell scope='row'>$ {parseInt(credito.TotalValorRestante)}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleViewCredito(credito);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredServicios.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}
      <ViewCredito data={selectedItem} open={openView} onClose={() => setOpenView(false)} />
    </div>
  );
};

export default Index;

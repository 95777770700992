import React, { useState } from 'react';
import { createProducto } from '../../../api/productos';
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material';

const CreateProducto = React.memo(({ onClose, lstCategorias, lstUnidades }: any) => {
  const [producto, setProducto] = useState({
    Pr_Nombre: '',
    Pr_Codigo: '',
    Pr_Ct_Id: '',
    Pr_Un_Id: '',
    Pr_Estado: 1,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Pr_Nombre: '',
    Pr_Codigo: '',
    Pr_Ct_Id: '',
    Pr_Un_Id: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProducto((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Pr_Nombre: '', Pr_Codigo: '', Pr_Ct_Id: '', Pr_Un_Id: '' };
    if (!producto.Pr_Nombre) tempErrors.Pr_Nombre = 'El nombre es requerido';
    if (!producto.Pr_Codigo) tempErrors.Pr_Codigo = 'El código es requerido';
    if (!producto.Pr_Ct_Id) tempErrors.Pr_Ct_Id = 'La categoría es requerida';
    if (!producto.Pr_Un_Id) tempErrors.Pr_Un_Id = 'La unidad es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await createProducto(producto);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Nombre' name='Pr_Nombre' value={producto.Pr_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Pr_Nombre} helperText={errors.Pr_Nombre} />
        <TextField label='Código' name='Pr_Codigo' value={producto.Pr_Codigo} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Pr_Codigo} helperText={errors.Pr_Codigo} />
        <TextField label='Categoría' name='Pr_Ct_Id' value={producto.Pr_Ct_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Pr_Ct_Id} helperText={errors.Pr_Ct_Id} select>
          {lstCategorias.map((categoria: any) => (
            <MenuItem key={categoria.Ct_Id} value={categoria.Ct_Id}>
              {categoria.Ct_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField label='Unidad' name='Pr_Un_Id' value={producto.Pr_Un_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Pr_Un_Id} helperText={errors.Pr_Un_Id} select>
          {lstUnidades.map((unidad: any) => (
            <MenuItem key={unidad.Un_Id} value={unidad.Un_Id}>
              {unidad.Un_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateProducto;

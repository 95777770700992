import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { NavigationProvider } from './context/navigation';
import { AuthProvider, useAuth } from './context/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

function App() {
  const { user, loading } = useAuth();  
  if (loading) {
    return <div >Cargando</div>;
  }

  if (user?.Us_Nombre) {    
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}

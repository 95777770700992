import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const ViewTipoIdentificacion = React.memo(({ tipoIdentificacion, onClose }: any) => {
  return (
    <div>
      <form>
        <TextField label="Nombre" value={tipoIdentificacion.Ti_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size="small" />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cerrar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default ViewTipoIdentificacion;

import React, { useState, useEffect } from 'react';
import { updateCliente } from '../../../api/clientes';
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material';

const EditCliente = React.memo(({ cliente, onClose, lstTipoIdentificacion }: any) => {
  const [formData, setFormData] = useState(cliente);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Cl_Ti_Id: '',
    Cl_Documento: '',
    Cl_Nombre: '',
    Cl_Telefono: '',
    Cl_Correo: '',
  });

  useEffect(() => {
    setFormData(cliente);
  }, [cliente]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = { Cl_Ti_Id: '', Cl_Nombre: '', Cl_Documento: '', Cl_Telefono: '', Cl_Correo: '' };
    if (!formData.Cl_Documento) tempErrors.Cl_Documento = 'El documento es requerido';
    if (!formData.Cl_Nombre) tempErrors.Cl_Nombre = 'El nombre es requerido';
    if (!formData.Cl_Telefono) tempErrors.Cl_Telefono = 'El teléfono es requerido';
    if (!formData.Cl_Correo) tempErrors.Cl_Correo = 'El correo es requerido';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    await updateCliente(formData.Cl_Id, formData);
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <form>
        <TextField label='Tipo Documento' name='Cl_Ti_Id' value={cliente.Cl_Ti_Id} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Cl_Ti_Id} helperText={errors.Cl_Ti_Id} select>
          {lstTipoIdentificacion.map((tipoIdentificacion: any) => (
            <MenuItem key={tipoIdentificacion.Ti_Id} value={tipoIdentificacion.Ti_Id}>
              {tipoIdentificacion.Ti_Nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField label='Documento' name='Cl_Documento' value={formData.Cl_Documento} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Documento} helperText={errors.Cl_Documento} />
        <TextField label='Nombre' name='Cl_Nombre' value={formData.Cl_Nombre} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Nombre} helperText={errors.Cl_Nombre} />
        <TextField label='Teléfono' name='Cl_Telefono' value={formData.Cl_Telefono} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Telefono} helperText={errors.Cl_Telefono} />
        <TextField label='Correo' name='Cl_Correo' value={formData.Cl_Correo} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem' }} size='small' error={!!errors.Cl_Correo} helperText={errors.Cl_Correo} />
        <TextField label='Dirección' name='Cl_Direccion' value={formData.Cl_Direccion} onChange={handleChange} fullWidth sx={{ marginBottom: '1rem' }} size='small' />
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant='outlined' color='error' size='small'>
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditCliente;

import { UseApi } from '../utils/axios-pettitions';

export async function getUsuarios() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los usuarios',
    };
  }
}

export async function getUsuarioById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el usuario',
    };
  }
}

export async function createUsuario(usuario: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios', 'POST', null, usuario);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el usuario',
    };
  }
}

export async function updateUsuario(id: number, usuario: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios', 'PUT', { id }, usuario);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el usuario',
    };
  }
}

export async function deleteUsuario(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el usuario',
    };
  }
}

export async function getPerfiles() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios/perfiles', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los perfiles',
    };
  }
}

export async function createPerfiles(perfil: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios/perfiles', 'POST', null, perfil);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el perfil',
    };
  }
}

export async function updatePerfiles(id: number, perfil: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`usuarios/perfiles`, 'PUT', {id}, perfil);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el perfil',
    };
  }
}

export async function deletePerfiles(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('usuarios/perfiles', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el perfil',
    };
  }
}

export async function getPermisos(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`usuarios/perfiles/permisos`, 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los perfiles',
    };
  }
}



export async function updatePermisos(id: number, permisos: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`usuarios/perfiles/permisos`, 'PUT', { id }, permisos);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar los permisos',
    };
  }
}
import { UseApi } from '../utils/axios-pettitions';

export async function getInventarios() {
    const { runApi } = UseApi();
    return await runApi('inventario', 'GET');
}

export async function createInventario(inventario: any, idUser: number) {
    const { runApi } = UseApi();
    return await runApi(`inventario?idUser=${idUser}`, 'POST', null, inventario);
}

export async function addStock(id: number, stock: any, idUser: number) {
    const { runApi } = UseApi();
    return await runApi(`inventario?id=${id}&action=add&idUser=${idUser}`, 'PUT', null, stock);
}

export async function removeStock(id: number, stock: any, idUser: number) {
    const { runApi } = UseApi();
    return await runApi(`inventario?id=${id}&action=remove&idUser=${idUser}`, 'PUT', null, stock);
}

export async function deleteInventario(id: number) {
    const { runApi } = UseApi();
    return await runApi(`inventario?id=${id}`, 'DELETE');
}

export async function getProductosEnInventario() {
    const { runApi } = UseApi();
    return await runApi(`inventario/getProductosEnInventario`, 'GET');
}
import React, { useState, useEffect, useCallback } from 'react';
import { getCategorias, deleteCategoria } from '../../../api/categorias';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box } from '@mui/material';
import { Edit, Delete, Visibility, Add, Update } from '@mui/icons-material';
import CreateCategoria from './_Create';
import EditCategoria from './_Edit';
import ViewCategoria from './_ViewById';
import { ConfirmDelete } from '../../../components/ConfirmDelete';
import { useAuth } from '../../../context/auth';

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { permisos } = useAuth();
  const { Prm_Config_Categ_Crear, Prm_Config_Categ_Modificar, Prm_Config_Categ_Eliminar } = permisos;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteCategoria(deleteId);
      fetchCategorias();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = useCallback(async () => {
    setLoading(true);
    const response = await getCategorias();
    if (response.isOk) {
      setCategorias(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const filteredCategorias = categorias.filter((categoria: any) => categoria.Ct_Nombre.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_Config_Categ_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Nuevo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}
            <IconButton onClick={fetchCategorias} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategorias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((categoria: any) => (
                  <TableRow key={categoria.Ct_Id}>
                    <TableCell>{categoria.Ct_Id}</TableCell>
                    <TableCell>{categoria.Ct_Nombre}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelectedCategoria(categoria);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                      {Prm_Config_Categ_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedCategoria(categoria);
                            setOpenEdit(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_Config_Categ_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(categoria.Ct_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredCategorias.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle>Crear Categoría</DialogTitle>
        <DialogContent>
          <CreateCategoria
            onClose={() => {
              setOpenCreate(false);
              fetchCategorias();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Editar Categoría</DialogTitle>
        <DialogContent>
          <EditCategoria
            categoria={selectedCategoria}
            onClose={() => {
              setOpenEdit(false);
              fetchCategorias();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>Ver Categoría</DialogTitle>
        <DialogContent>
          <ViewCategoria categoria={selectedCategoria} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar esta categoría?' />
    </div>
  );
};

export default Categorias;
